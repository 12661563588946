import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import pageService from "./pageService";

const initialState = {
  page: null,
  pages: [],
  pageViews: 0,
  prodClicks: 0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create Ad
export const createAdPage = createAsyncThunk(
  "page/create",
  async (newFormData, thunkAPI) => {
    try {
      return await pageService.createAdPage(newFormData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// getAds Pages
export const getAdPages = createAsyncThunk(
  "page/getAdsPages",
  async (_, thunkAPI) => {
    try {
      return await pageService.getAdPages();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// deleteAd
export const deleteAdPage = createAsyncThunk(
  "page/deleteAdPage",
  async (id, thunkAPI) => {
    try {
      return await pageService.deleteAdPage(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// getAd Page
export const getAdPage = createAsyncThunk(
  "page/getAdPage",
  async (id, thunkAPI) => {
    try {
      return await pageService.getAdPage(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// updateAd
export const updateAdPage = createAsyncThunk(
  "page/updateAdPage",
  async ({ editID, newFormData }, thunkAPI) => {
    try {
      return await pageService.updateAdPage(editID, newFormData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    RESET_PAGE(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
    CALC_PAGE_VIEWS(state, action) {
      const totalPageViews = state.pages.reduce(
        (total, ad) => total + ad.views,
        0
      );
      state.pageViews = totalPageViews;
    },
    CALC_AD_CLICKS(state, action) {
      const totalClicks = state.pages.reduce(
        (total, ad) => total + ad.clicks,
        0
      );

      state.prodClicks = totalClicks;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Ad Page
      .addCase(createAdPage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAdPage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Ads Page Created";
        state.isError = false;
        state.page = action.payload;
        toast.success("Ads Page Created");
        // console.log(action.payload);
      })
      .addCase(createAdPage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.page = null;
        toast.error(action.payload);
        // console.log(action.payload);
      })
      // Get Ad Pages
      .addCase(getAdPages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdPages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.pages = action.payload;
        // console.log(action.payload);
      })
      .addCase(getAdPages.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // Delete Ad Page
      .addCase(deleteAdPage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAdPage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success(action.payload);
        // console.log(action.payload);
      })
      .addCase(deleteAdPage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
        // console.log(action.payload);
      })
      //   getAd Page
      .addCase(getAdPage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdPage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.page = action.payload;
        // console.log(action.payload);
      })
      .addCase(getAdPage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
        console.log(action.payload);
      })
      //   updateAd Page
      .addCase(updateAdPage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAdPage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.page = action.payload;
        toast.success("Ad Page updated successfully");
      })
      .addCase(updateAdPage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
        console.log(action.payload);
      });
  },
});

export const { RESET_PAGE, CALC_PAGE_VIEWS, CALC_AD_CLICKS } =
  pageSlice.actions;

export const selectPages = (state) => state.page.pages;

export default pageSlice.reducer;
