import Tooltip from "rc-tooltip";
import React from "react";
import { MdContentCopy } from "react-icons/md";
import { copyText } from "../../utils";

const ClickToCopy = ({ text }: any) => {
  return (
    <div>
      <Tooltip
        placement="top"
        trigger={["hover"]}
        overlay={<span>Click to Copy</span>}
        overlayClassName="tooltip"
      >
        <MdContentCopy
          id="copy-text"
          size={20}
          className="copy-icon"
          onClick={() => copyText(text)}
        />
      </Tooltip>
    </div>
  );
};

export default ClickToCopy;
