import React, { useEffect, useState } from "react";
import PageMenu from "../../components/pageMenu/PageMenu";
import { item1, item2 } from "../createAd/CreateAd";
import {
  deleteAd,
  getAds,
  selectAds,
  setDefaultAd,
} from "../../redux/features/ads/adSlice";
import { useDispatch } from "react-redux";
import "./ViewAds.scss";
import { useSelector } from "react-redux";
import { Spinner } from "../../components/loader/Loader";
import Search from "../../components/search/Search";
import { formatNumber, shortenText } from "../../utils";
import Tooltip from "rc-tooltip";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactPaginate from "react-paginate";
import {
  FILTER_ADS,
  selectFilteredAds,
} from "../../redux/features/filter/filterSlice";
import { BsCheck2All } from "react-icons/bs";
import { MdOutlineDisabledByDefault } from "react-icons/md";

export interface AdsProp {
  _id?: any;
  title?: string;
  heading?: string;
  desc?: string;
  link?: string;
  linkText?: string;
  clicks?: any;
  defaultAd?: string;
  referrer?: string;
}

const ViewAds = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>("");
  const { ads, isLoading, isError, message } = useSelector(
    (state: any) => state.ads
  );
  const prods = useSelector(selectAds);
  const filteredProds = useSelector(selectFilteredAds);

  useEffect(() => {
    // @ts-ignore
    dispatch(getAds());
  }, [dispatch]);
  // console.log(ads);

  // Begin Pagination
  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filteredProds.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredProds.length / itemsPerPage);

  // Invoke when user click
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % filteredProds.length;
    setItemOffset(newOffset);
  };
  // End Pagination

  const removeAd = async (alias: string) => {
    // @ts-ignore
    await dispatch(deleteAd(alias));
    // @ts-ignore
    dispatch(getAds());
  };

  const confirmDelete = (alias: string | any) => {
    confirmAlert({
      title: "Delete This Ad",
      message: "Are you sure to do delete this Ad?",
      buttons: [
        {
          label: "Delete",
          onClick: () => removeAd(alias),
        },
        {
          label: "Cancel",
          // onClick: () => alert("Click No"),
        },
      ],
    });
  };

  useEffect(() => {
    dispatch(FILTER_ADS({ ads, search }));
  }, [dispatch, ads, search]);

  const confirmDefaultAd = (alias: string | any) => {
    confirmAlert({
      title: "Set/Remove Default Ad",
      message:
        "A default Ad overides all Ads on all your pages. You can set a default ad to give that ad massive visibility across all your pages.",
      buttons: [
        {
          label: "Set",
          onClick: () => setDefAd(alias),
        },
        {
          label: "Cancel",
          // onClick: () => alert("Click No"),
        },
      ],
    });
  };

  const setDefAd = async (id: string) => {
    // @ts-ignore
    await dispatch(setDefaultAd(id));
    // @ts-ignore
    dispatch(getAds());
  };

  return (
    <div>
      <br />
      <h3>View All Ads</h3>
      <PageMenu link1={item1} link2={item2} />
      <br />
      <div className="view-prod">
        <hr />
        {isLoading && <Spinner />}
        <div className="table">
          <div className="--flex-between">
            <div className="sort">
              <div className="--flex-start" style={{ width: "200px" }}>
                <span>
                  <h4
                    style={{ width: "100px" }}
                  >{`Ads~ ${filteredProds.length} `}</h4>
                </span>
              </div>
            </div>
            <span>
              <Search
                value={search}
                placeholder="Search"
                onChange={(e: any) => setSearch(e.target.value)}
              />
            </span>
          </div>
          {/* Table */}
          {!isLoading && prods.length === 0 ? (
            <p>No Product found...</p>
          ) : (
            <table style={{ zIndex: 1 }}>
              <thead>
                <tr>
                  <th>s/n</th>
                  <th>Title</th>
                  <th>Heading</th>
                  <th>Link</th>
                  <th>Clicks</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody style={{ zIndex: 1 }}>
                {currentItems.map((prod: object, index: number) => {
                  // @ts-ignore
                  const { _id, title, heading, link, clicks, defaultAd } = prod;

                  return (
                    <tr
                      key={_id}
                      className={defaultAd === "true" ? "default-ad" : ""}
                    >
                      <td>{index + 1}</td>
                      <td>{title || ""}</td>
                      <td>{shortenText(heading, 25)}</td>

                      <td>
                        {shortenText(
                          link?.replace("https://", "").replace("www.", ""),
                          15
                        )}
                      </td>

                      <td>{formatNumber.format(clicks)}</td>
                      {/* <td>{defaultAd}</td> */}

                      <td className="icons">
                        <span>
                          <Tooltip
                            placement="top"
                            trigger={["hover"]}
                            overlay={<span>Edit Ad</span>}
                          >
                            <Link to={`/create-ad?edit=${_id}`}>
                              <FaEdit size={20} color="green" />
                            </Link>
                          </Tooltip>
                        </span>
                        <span>
                          <Tooltip
                            placement="top"
                            trigger={["hover"]}
                            overlay={<span>Delete Ad</span>}
                          >
                            <FaTrashAlt
                              size={20}
                              color="red"
                              onClick={() => confirmDelete(_id)}
                            />
                          </Tooltip>
                        </span>
                        <span>
                          <Tooltip
                            placement="top"
                            trigger={["hover"]}
                            overlay={
                              <span>
                                {defaultAd === "true"
                                  ? "Remove Default Ad"
                                  : "Set Default Ad"}
                              </span>
                            }
                          >
                            <span>
                              {defaultAd === "true" ? (
                                <MdOutlineDisabledByDefault
                                  size={25}
                                  color="red"
                                  onClick={() => confirmDefaultAd(_id)}
                                />
                              ) : (
                                <BsCheck2All
                                  size={25}
                                  color="green"
                                  onClick={() => confirmDefaultAd(_id)}
                                />
                              )}
                            </span>
                          </Tooltip>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <hr />
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="Prev"
          // @ts-ignore
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="activePage"
        />
      </div>
    </div>
  );
};

export default ViewAds;
