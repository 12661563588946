import React, { useEffect, useState } from "react";
import { BiLogIn } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Card from "../../components/card/Card";
import Loader from "../../components/loader/Loader";
import PasswordInput from "../../components/passwordInput/PasswordInput";
import { validateEmail } from "../../redux/features/auth/authService";
import {
  login,
  loginWithGoogle,
  RESET,
  sendLoginCode,
} from "../../redux/features/auth/authSlice";
import styles from "./auth.module.scss";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  GithubAuthProvider,
  TwitterAuthProvider,
} from "firebase/auth";
import { auth } from "../../firebase/config";
import { AiFillGoogleCircle } from "react-icons/ai";
import { AiFillGithub } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
// import { GoogleLogin } from "@react-oauth/google";

const initialState = {
  email: "",
  password: "",
};

const Login = () => {
  const [formData, setFormData] = useState(initialState);
  const { email, password } = formData;

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, isLoggedIn, isSuccess, message, isError, twoFactor } =
    useSelector((state: any) => state.auth);

  // const loginUser = async (e: any) => {
  //   e.preventDefault();

  //   if (!email || !password) {
  //     return toast.error("All fields are required");
  //   }

  //   if (!validateEmail(email)) {
  //     return toast.error("Please enter a valid email");
  //   }

  //   const userData = {
  //     email,
  //     password,
  //   };

  //   // Login with Email and Password
  //   signInWithEmailAndPassword(auth, email, password)
  //     .then((userCredential) => {
  //       const user = userCredential.user;
  //       console.log(user);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       toast.error(error.message);
  //     });
  // };

  // Login with Google

  const provider = new GoogleAuthProvider();
  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        console.log(user);

        // console.log("Login Frontend");
        dispatch(
          // @ts-ignore
          loginWithGoogle({ userToken: user.accessToken })
        );
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    if (isSuccess && isLoggedIn) {
      navigate("/");
    }

    dispatch(RESET());
  }, [isLoggedIn, isSuccess, dispatch, navigate, message]);

  const auth = getAuth();
  const gitubProvider = new GithubAuthProvider();
  const signInWithGithub = () => {
    signInWithPopup(auth, gitubProvider)
      .then((result) => {
        const user = result.user;
        console.log(user);
        dispatch(
          // @ts-ignore
          loginWithGoogle({ userToken: user.accessToken })
        );
      })
      .catch((error) => {
        const errorMessage = error.message;
        // console.log(errorMessage);
        toast.error(errorMessage);
      });
  };

  const twitterProvider = new TwitterAuthProvider();
  const signInWithTwitter = () => {
    signInWithPopup(auth, twitterProvider)
      .then((result) => {
        const user = result.user;
        console.log(user);
        dispatch(
          // @ts-ignore
          loginWithGoogle({ userToken: user.accessToken })
        );
      })
      .catch((error) => {
        const errorMessage = error.message;
        // console.log(errorMessage);
        toast.error(errorMessage);
      });
  };

  return (
    <div className={`container ${styles.auth}`}>
      {isLoading && <Loader />}
      <Card>
        <div className={styles.form}>
          <div className="--flex-center">
            <BiLogIn size={35} color="#999" />
          </div>
          <h2>Login</h2>
          <div className="--flex-center">
            <button
              className="--btn --btn-block --btn-lg --btn-danger"
              onClick={signInWithGoogle}
            >
              <AiFillGoogleCircle />
              &nbsp; Login With Google
            </button>
          </div>

          {/* <p className="--text-center --fw-bold">or</p>

          <div className="--flex-center">
            <button
              className="--btn --btn-block --btn-lg --btn-primary"
              onClick={signInWithTwitter}
            >
              <BsTwitter />
              &nbsp; Login With Twitter
            </button>
          </div> */}

          <p className="--text-center --fw-bold">or</p>

          <div className="--flex-center">
            <button
              className="--btn --btn-block --btn-lg"
              onClick={signInWithGithub}
            >
              <AiFillGithub />
              &nbsp; Login With GitHub
            </button>
          </div>
          {/* <form onSubmit={loginUser}>
            <input
              type="email"
              placeholder="Email"
              required
              name="email"
              value={email}
              onChange={handleInputChange}
            />
            <PasswordInput
              placeholder="Password"
              name="password"
              value={password}
              onChange={handleInputChange}
            />

            <button type="submit" className="--btn --btn-primary --btn-block">
              Login
            </button>
          </form> */}
          {/* <Link to="/forgot">Forgot Password</Link> */}
          {/* <span className={styles.register}>
            <Link to="/">Home</Link>
            <p> &nbsp; Don't have an account? &nbsp;</p>
            <Link to="/register">Register</Link>
          </span> */}
        </div>
      </Card>
    </div>
  );
};

export default Login;
