import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./components/layout/Layout";
import Sidebar from "./components/sidebar/Sidebar";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import EditLink from "./pages/editLink/EditLink";
import Home from "./pages/home/Home";
import CreateLink from "./pages/createLink/createLink/CreateLink";
import LinkDetails from "./pages/linkDetails/LinkDetails";
import Profile from "./pages/profile/Profile";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  SET_LOGIN,
  getLoginStatus,
  getUser,
} from "./redux/features/auth/authSlice";
import SubList from "./pages/pricing/SubList";
import { useSelector } from "react-redux";
import Success from "./pages/success/Success";
import Cancel from "./pages/success/Cancel";
import Account from "./pages/account/Account";
import AdsBeforeLink from "./pages/adsBeforeLink/AdsBeforeLink";
import CreateAd from "./pages/createAd/CreateAd";
import ViewAds from "./pages/viewAds/ViewAds";
import CreateAdPage from "./pages/createAdPage/CreateAdPage";
import ViewAdPages from "./pages/viewAdPages/ViewAdPages";
import { BACKEND_URL } from "./redux/features/link/linkService";
import NotFound from "./pages/notFound/NotFound";
import CustomDomain from "./pages/customDomain/CustomDomain";
import ViewCustomDomains from "./pages/customDomain/ViewCustomDomains";
import { GET_DOMAINS } from "./redux/features/customDomain/domainSlice";
import Contact from "./pages/contact/Contact";

axios.defaults.withCredentials = true;
// Change

function App() {
  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state: any) => state.auth);
  const [domains, setDomains] = useState(["olink.to"]);

  useEffect(() => {
    async function loginStatus() {
      // @ts-ignore
      const status = await dispatch(getLoginStatus());

      dispatch(SET_LOGIN(status));
    }
    loginStatus();
  }, [dispatch]);

  useEffect(() => {
    if (user === null) {
      // @ts-ignore
      dispatch(getUser());
    }
    // console.log(user);
  }, [dispatch, user]);

  useEffect(() => {
    if (
      user !== null &&
      user?.plan !== "Free" &&
      user?.plan !== "Basic One" &&
      user?.customDomains &&
      user?.customDomains.length > 0
    ) {
      const verifiedDomains = getVerifiedDomains(user.customDomains);
      if (verifiedDomains.length > 0) {
        setDomains((prevDomains) => [...verifiedDomains, ...prevDomains]);
      }
    }
  }, [dispatch, user]);

  useEffect(() => {
    // @ts-ignore
    dispatch(GET_DOMAINS(domains));
  }, [dispatch, domains]);

  function getVerifiedDomains(customDomains: any) {
    const verifiedDomains = customDomains // @ts-ignore
      .filter((domain) => domain.verificationStatus === "verified") // @ts-ignore
      .map((domain) => domain.name);
    return verifiedDomains;
  }

  useEffect(() => {
    const getSubStatus = async () => {
      try {
        const { data } = await axios.get(
          `${BACKEND_URL}/api/subs/getSubStatus`
        );
        return data.role;
      } catch (error) {
        // do nothing
      }
    };

    if (isLoggedIn && user !== null) {
      getSubStatus();
    }
  }, [isLoggedIn, user]);

  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/pricing"
          element={
            <Sidebar>
              <Layout>
                <SubList />
              </Layout>
            </Sidebar>
          }
        />
        <Route
          path="/custom-domains"
          element={
            <Sidebar>
              <Layout>
                <CustomDomain />
              </Layout>
            </Sidebar>
          }
        />
        <Route
          path="/view-custom-domains"
          element={
            <Sidebar>
              <Layout>
                <ViewCustomDomains />
              </Layout>
            </Sidebar>
          }
        />
        <Route
          path="/success"
          element={
            <Layout>
              <Success />
            </Layout>
          }
        />
        <Route
          path="/cancel"
          element={
            <Layout>
              <Cancel />
            </Layout>
          }
        />

        <Route
          path="/"
          element={
            <Sidebar>
              <Layout>
                <Home />
              </Layout>
            </Sidebar>
          }
        />
        <Route
          path="/create-link"
          element={
            <Sidebar>
              <Layout>
                <CreateLink />
              </Layout>
            </Sidebar>
          }
        />
        <Route
          path="/edit-link/:alias"
          element={
            <Sidebar>
              <Layout>
                <EditLink />
              </Layout>
            </Sidebar>
          }
        />
        <Route
          path="/link/:alias"
          element={
            <Sidebar>
              <Layout>
                <LinkDetails />
              </Layout>
            </Sidebar>
          }
        />
        <Route
          path="/profile"
          element={
            <Sidebar>
              <Layout>
                <Profile />
              </Layout>
            </Sidebar>
          }
        />
        <Route
          path="/account"
          element={
            <Sidebar>
              <Layout>
                <Account />
              </Layout>
            </Sidebar>
          }
        />
        <Route
          path="/ads-before-link"
          element={
            <Sidebar>
              <Layout>
                <AdsBeforeLink />
              </Layout>
            </Sidebar>
          }
        />
        <Route
          path="/create-ad"
          element={
            <Sidebar>
              <Layout>
                <CreateAd />
              </Layout>
            </Sidebar>
          }
        />
        <Route
          path="/view-ads"
          element={
            <Sidebar>
              <Layout>
                <ViewAds />
              </Layout>
            </Sidebar>
          }
        />
        <Route
          path="/create-ad-page"
          element={
            <Sidebar>
              <Layout>
                <CreateAdPage />
              </Layout>
            </Sidebar>
          }
        />
        <Route
          path="/view-ad-pages"
          element={
            <Sidebar>
              <Layout>
                <ViewAdPages />
              </Layout>
            </Sidebar>
          }
        />
        <Route
          path="/contact-us"
          element={
            <Sidebar>
              <Layout>
                <Contact />
              </Layout>
            </Sidebar>
          }
        />
        <Route
          path="*"
          element={
            <Layout>
              <NotFound />
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
