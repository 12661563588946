import React, { useEffect, useState } from "react";
import Card from "../../card/Card";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  PointElement,
  LineElement,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import { isoCountries } from "../../../data/country";
import ReferrerChart from "./ReferrerChart";
import { getCountryName } from "../../../utils";
import CountryChart from "./CountryChart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
};

const backgroundColor = [
  "rgba(255, 99, 132, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 159, 64, 1)",
];

const LinkCharts = ({ links, link, alias }: any) => {
  const [clicksPer, setClicksPer] = useState("day");
  const [noOfDays, setNoOfDays] = useState(7);

  const [linkReferrer, setLinkReferrer] = useState<any>([
    {
      source: "null",
      count: 0,
    },
  ]);
  const [countries, setCountries] = useState<any>([
    {
      name: "null",
      count: 0,
    },
  ]);

  const [data, setData] = useState<any>({
    labels: ["null", "null"],
    datasets: [
      {
        label: "# of Clicks",
        data: [50, 50],
        backgroundColor,
      },
    ],
  });
  const [countryData, setCountryData] = useState<any>({
    labels: ["null", "null"],
    datasets: [
      {
        label: "# of Clicks",
        data: [50, 50],
        backgroundColor,
      },
    ],
  });

  const [clicksPerDay, setClicksPerDay] = useState<any>({
    labels: ["null", "null"],
    datasets: [
      {
        label: "Clicks",
        data: [50, 50],
        backgroundColor: "rgba(54, 162, 235, 1)",
      },
    ],
  });

  const [clicksPerMonth, setClicksPerMonth] = useState<any>({
    labels: ["null", "null"],
    datasets: [
      {
        label: "Clicks",
        data: [50, 50],
        backgroundColor: "rgba(54, 162, 235, 1)",
      },
    ],
  });
  const [clicksPerYear, setClicksPerYear] = useState<any>({
    labels: ["null", "null"],
    datasets: [
      {
        label: "Clicks",
        data: [50, 50],
        backgroundColor: "rgba(54, 162, 235, 1)",
      },
    ],
  });

  //   Populate from redux links (Referrer)
  useEffect(() => {
    if (links.length !== 0) {
      const rLink = links.find((link: any) => link.shortLink === alias);

      //   Set Refferer
      setLinkReferrer(rLink?.referrer);
      setCountries(rLink?.country);

      // //   Set Referrer Chart
      const newLabel = [];
      const newData = [];
      for (var i of rLink?.referrer) {
        newLabel.push(i.source);
        newData.push(i.count);
      }
      setData({
        datasets: [
          {
            label: "# of Clicks",
            data: newData,
            backgroundColor,
          },
        ],
        labels: newLabel,
      });

      // //   Set Country chart
      const cLabel = [];
      const cData = [];
      for (var c of rLink?.country) {
        cLabel.push(getCountryName(c.name));
        cData.push(c.count);
      }
      setCountryData({
        datasets: [
          {
            label: "# of Clicks",
            data: cData,
            backgroundColor,
          },
        ],
        labels: cLabel,
      });

      //   Clicks Per Day
      const cpdLabel = [];
      const cpdData = [];
      const filteredClicks: any = rLink?.clicksPerDay.slice(-noOfDays);
      // console.log(filteredClicks);
      for (var cpd of filteredClicks) {
        cpdLabel.push(cpd.day.slice(0, -6));
        cpdData.push(cpd.count);
      }

      setClicksPerDay({
        labels: cpdLabel || ["null", "null"],
        datasets: [
          {
            label: "Clicks",
            data: cpdData || [50, 50],
            backgroundColor: "rgba(54, 162, 235, 1)",
          },
        ],
      });

      //   Clicks Per Month
      const cpmLabel: any = [];
      const cpmData: any = [];
      const filteredClicksM: any = rLink?.clicksPerMonth.slice(-12);
      for (var cpm of filteredClicksM) {
        cpmLabel.push(cpm.month);
        cpmData.push(cpm.count);
      }
      setClicksPerMonth({
        labels: cpmLabel,
        datasets: [
          {
            label: "Clicks",
            data: cpmData,
            backgroundColor: "rgba(54, 162, 235, 1)",
          },
        ],
      });

      //   Clicks Per Year
      const cpyLabel: any = [];
      const cpyData: any = [];
      const filteredClicksY: any = rLink?.clicksPerYear.slice(-12);
      for (var cpy of filteredClicksY) {
        cpyLabel.push(cpy.year);
        cpyData.push(cpy.count);
      }

      setClicksPerYear({
        labels: cpyLabel,
        datasets: [
          {
            label: "Clicks",
            data: cpyData,
            backgroundColor: "rgba(54, 162, 235, 1)",
          },
        ],
      });
    }
  }, [links, alias, noOfDays]);

  //   Fetch data from server
  useEffect(() => {
    if (links.length === 0 && link !== null) {
      setLinkReferrer(link?.referrer);
      setCountries(link?.country);

      const newLabel = [];
      const newData = [];
      for (var i of link?.referrer || []) {
        newLabel.push(i.source);
        newData.push(i.count);
      }
      setData({
        datasets: [
          {
            label: "# of Clicks",
            data: newData,
            backgroundColor,
          },
        ],
        labels: newLabel,
      });

      //   Set Country chart
      const cLabel = [];
      const cData = [];
      for (var c of link?.country) {
        cLabel.push(getCountryName(c.name));
        cData.push(c.count);
      }
      setCountryData({
        datasets: [
          {
            label: "# of Clicks",
            data: cData,
            backgroundColor,
          },
        ],
        labels: cLabel,
      });

      //   Clicks Per Day
      const cpdLabel = [];
      const cpdData = [];
      const filteredClicks: any = link?.clicksPerDay.slice(-noOfDays);
      for (var cpd of filteredClicks) {
        cpdLabel.push(cpd.day.slice(0, -6));
        cpdData.push(cpd.count);
      }
      setClicksPerDay({
        labels: cpdLabel,
        datasets: [
          {
            label: "Clicks",
            data: cpdData,
            backgroundColor: "rgba(54, 162, 235, 1)",
          },
        ],
      });

      //   Clicks Per Month
      const cpmLabel: any = [];
      const cpmData: any = [];
      const filteredClicksM: any = link?.clicksPerMonth.slice(-12);
      for (var cpm of filteredClicksM) {
        cpmLabel.push(cpm.month);
        cpmData.push(cpm.count);
      }
      setClicksPerMonth({
        labels: cpmLabel,
        datasets: [
          {
            label: "Clicks",
            data: cpmData,
            backgroundColor: "rgba(54, 162, 235, 1)",
          },
        ],
      });

      //   Clicks Per Year
      const cpyLabel: any = [];
      const cpyData: any = [];
      const filteredClicksY: any = link?.clicksPerYear.slice(-12);
      for (var cpy of filteredClicksY) {
        cpyLabel.push(cpy.year);
        cpyData.push(cpy.count);
      }

      setClicksPerYear({
        labels: cpyLabel,
        datasets: [
          {
            label: "Clicks",
            data: cpyData,
            backgroundColor: "rgba(54, 162, 235, 1)",
          },
        ],
      });
    }
  }, [links, link, noOfDays]);

  return (
    <div>
      <Card cardClass="dcard dcard-purple">
        <div className="--flex-between">
          <div className="sort">
            <select
              value={clicksPer}
              onChange={(e: any) => setClicksPer(e.target.value)}
            >
              <option value={"day"}>{"Clicks Per Day"}</option>
              <option value={"month"}>{"Clicks Per Month"}</option>
              <option value={"year"}>{"Clicks Per Year"}</option>
            </select>
          </div>
          {clicksPer === "day" && (
            <div className="sort">
              <select
                value={noOfDays}
                onChange={(e: any) => setNoOfDays(e.target.value)}
              >
                <option value={7}>{"Last 7 day"}</option>
                <option value={14}>{"Last 14 days"}</option>
                <option value={30}>{"Last 30 days"}</option>
              </select>
            </div>
          )}
        </div>
        <hr />
        {clicksPer === "day" && <Line options={options} data={clicksPerDay} />}
        {clicksPer === "month" && (
          <Line options={options} data={clicksPerMonth} />
        )}
        {clicksPer === "year" && (
          <Line options={options} data={clicksPerYear} />
        )}
      </Card>
      <br />
      <ReferrerChart linkReferrer={linkReferrer} data={data} />
      <br />
      <CountryChart countries={countries} countryData={countryData} />
    </div>
  );
};

export default LinkCharts;
