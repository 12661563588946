import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filteredLinks: [],
  filteredAds: [],
  filteredPages: [],
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    FILTER_LINKS(state, action) {
      const { links, search } = action.payload;
      let tempLinks = [];
      tempLinks = links?.filter(
        (link) =>
          link.title.toLowerCase().includes(search.toLowerCase()) ||
          link.longLink.toLowerCase().includes(search.toLowerCase()) ||
          link.shortLink.toLowerCase().includes(search.toLowerCase())
      );

      state.filteredLinks = tempLinks;
    },
    FILTER_ADS(state, action) {
      const { ads, search } = action.payload;
      let tempAds = [];
      tempAds = ads?.filter(
        (ad) =>
          ad.title.toLowerCase().includes(search.toLowerCase()) ||
          ad.heading.toLowerCase().includes(search.toLowerCase()) ||
          ad.link.toLowerCase().includes(search.toLowerCase())
      );

      state.filteredAds = tempAds;
    },
    FILTER_PAGES(state, action) {
      const { pages, search } = action.payload;
      let tempPages = [];
      tempPages = pages?.filter(
        (page) =>
          page.title.toLowerCase().includes(search.toLowerCase()) ||
          page.ads.toLowerCase().includes(search.toLowerCase())
      );

      state.filteredPages = tempPages;
    },

    FILTER_BY_TAG(state, action) {
      const { links, tag } = action.payload;
      let tempLinks = [];
      if (tag === "All") {
        tempLinks = links;
      } else {
        tempLinks = links.filter((link) => link.tag === tag);
      }
      state.filteredLinks = tempLinks;
    },
  },
});

export const { FILTER_LINKS, FILTER_ADS, FILTER_PAGES, FILTER_BY_TAG } =
  filterSlice.actions;

export const selectFilteredLinks = (state) => state.filter.filteredLinks;
export const selectFilteredAds = (state) => state.filter.filteredAds;
export const selectFilteredPages = (state) => state.filter.filteredPages;

export default filterSlice.reducer;
