import { toast } from "react-toastify";
import { isoCountries } from "../data/country";

// Backend url
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const API_URL = `${BACKEND_URL}/api/users/`;
export const ADS_API_URL = `${BACKEND_URL}/api/ads/`;

// Shorten Text
export const shortenText = (text: any, n: number) => {
  if (text.length > n) {
    const shoretenedText = text.substring(0, n).concat("...");
    return shoretenedText;
  }
  return text;
};

// Validate URL
export const validateUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

// Copy Text to clipboard
export const copyText = async (text: string | any) => {
  if ("clipboard" in navigator) {
    await navigator.clipboard.writeText(text);
    return toast.success(`Text copied to clipboard.`);
  } else {
    return toast.error("Clipboard API not ready, try again.");
  }
};

// Get Country Name
export // Convert country code to country name
function getCountryName(countryCode: any) {
  if (isoCountries.hasOwnProperty(countryCode)) {
    // @ts-ignore
    return isoCountries[countryCode];
  } else {
    return countryCode;
  }
}

// Format Number
export let formatNumber = Intl.NumberFormat("en", { notation: "compact" });

// Check valid domain or subdomain
export function isValidDomain(str: string) {
  // Regular expression to match a valid domain or subdomain
  var pattern = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,}$/i;
  return pattern.test(str);
}

// Subscription Plans
export const free = "Free";
export const basic_one = "Basic One";
export const basic_two = "Basic Two";
export const basic_three = "Basic Three";
export const pro = "Pro";
export const business = "Business";
export const enterprise = "Enterprise";
