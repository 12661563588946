import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import adService from "./adService";

const initialState = {
  ad: null,
  ads: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create Ad
export const createAd = createAsyncThunk(
  "ads/create",
  async (formData, thunkAPI) => {
    try {
      return await adService.createAd(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// getAds
export const getAds = createAsyncThunk("ads/getAds", async (_, thunkAPI) => {
  try {
    return await adService.getAds();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// deleteAd
export const deleteAd = createAsyncThunk(
  "ads/deleteAd",
  async (id, thunkAPI) => {
    try {
      return await adService.deleteAd(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// getAd
export const getAd = createAsyncThunk("ads/getAd", async (id, thunkAPI) => {
  try {
    return await adService.getAd(id);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
// updateAd
export const updateAd = createAsyncThunk(
  "ads/updateAd",
  async ({ editID, newFormData }, thunkAPI) => {
    try {
      return await adService.updateAd(editID, newFormData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// setDefaultAd
export const setDefaultAd = createAsyncThunk(
  "ads/setDefaultAd",
  async (id, thunkAPI) => {
    try {
      return await adService.setDefaultAd(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const adSlice = createSlice({
  name: "ads",
  initialState,
  reducers: {
    RESET_AD(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Ad
      .addCase(createAd.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Ads Created";
        state.isError = false;
        state.ad = action.payload;
        toast.success("Ads Created");
        // console.log(action.payload);
      })
      .addCase(createAd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.ad = null;
        toast.error(action.payload);
        // console.log(action.payload);
      })
      //   Get Ads
      .addCase(getAds.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAds.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.ads = action.payload;
        // console.log(action.payload);
      })
      .addCase(getAds.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      //   deleteAd
      .addCase(deleteAd.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success(action.payload);
      })
      .addCase(deleteAd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
        // console.log(action.payload);
      })
      //   getAd
      .addCase(getAd.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.ad = action.payload;
      })
      .addCase(getAd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
        // console.log(action.payload);
      })
      //   updateAd
      .addCase(updateAd.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Ad updated successfully";
        state.isError = false;
        state.ad = action.payload;
        toast.success("Ad updated successfully");
      })
      .addCase(updateAd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
        // console.log(action.payload);
      })
      //   setDefaultAd
      .addCase(setDefaultAd.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setDefaultAd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // console.log(action.payload);
        toast.success(action.payload);
      })
      .addCase(setDefaultAd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
        // console.log(action.payload);
      });
  },
});

export const { RESET_AD } = adSlice.actions;

export const selectAds = (state) => state.ads.ads;

export default adSlice.reducer;
