import React, { useEffect, useState } from "react";
import Card from "../../components/card/Card";
import "./LinkDetails.scss";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import LinkCharts from "../../components/link/linkCharts/LinkCharts";
import {
  CALC_CLICKS_THIS_MONTH,
  CALC_CLICKS_TODAY,
  getLink,
  selectClicksThisMonth,
  selectClicksToday,
} from "../../redux/features/link/linkSlice";
import { formatNumber } from "../../utils";
import InfoBox from "../../components/infoBox/InfoBox";
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { GiClick } from "react-icons/gi";
import { MdAdsClick } from "react-icons/md";

interface LinkProps {
  title: string;
  longURL: string;
  shortURL: string;
  clicks: number;
  domain?: string;
  uniqueClicks?: number;
  referrer: [];
  country?: [];
  clicksPerDay?: [];
  clicksPerMonth?: [];
  createdAt: string;
}

// Icons
const icon1 = <TbBrandGoogleAnalytics size={40} color="#b624ff" />;
const icon2 = <AiOutlineUserSwitch size={40} color="#32963d" />;
const icon3 = <GiClick size={40} color="#03a5fc" />;
const icon4 = <MdAdsClick size={40} color="#c41849" />;

export const formatNumbers = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const LinkDetails = () => {
  const { alias } = useParams();
  const dispatch = useDispatch();

  const [linkData, setLinkData] = useState<LinkProps>({
    title: "",
    longURL: "",
    shortURL: "",
    clicks: 0,
    domain: "",
    uniqueClicks: 0,
    referrer: [],
    clicksPerDay: [],
    clicksPerMonth: [],
    createdAt: "",
  });
  const {
    title,
    longURL,
    shortURL,
    clicks,
    domain,
    uniqueClicks,
    referrer,
    clicksPerDay,
    clicksPerMonth,
    createdAt,
  } = linkData;

  const { links, link, isLoading, isSuccess, message } = useSelector(
    (state: any) => state.link
  );

  // Get Link info from redux Links
  useEffect(() => {
    if (links.length > 0) {
      const rLink = links.find((link: any) => link.shortLink === alias);
      // console.log(rLink);
      setLinkData({
        title: rLink?.title || "",
        longURL: rLink?.longLink || "",
        shortURL: rLink?.shortLink || "",
        clicks: rLink?.clicks || 0,
        domain: rLink?.domain || "",
        uniqueClicks: rLink?.uniqueClicks || 0,
        referrer: rLink?.referrer || [],
        clicksPerDay: rLink?.clicksPerDay || [],
        clicksPerMonth: rLink?.clicksPerMonth || [],
        createdAt: rLink?.createdAt || "",
      });
    }
  }, [links, alias]);

  // Fetch Link
  useEffect(() => {
    if (links.length === 0) {
      // @ts-ignore
      dispatch(getLink(alias));
    }
  }, [dispatch, links, alias]);

  useEffect(() => {
    if (links.length === 0 && link !== null) {
      setLinkData({
        title: link?.title || "",
        longURL: link?.longLink || "",
        shortURL: link?.shortLink || "",
        clicks: link?.clicks || 0,
        domain: link?.domain || "",
        uniqueClicks: link?.uniqueClicks || 0,
        referrer: link?.referrer || [],
        clicksPerDay: link?.clicksPerDay || [],
        clicksPerMonth: link?.clicksPerMonth || [],
        createdAt: link?.createdAt || "",
      });
    }
  }, [links, link]);

  const clicksToday: number = useSelector(selectClicksToday);
  const clicksThisMonth: number = useSelector(selectClicksThisMonth);

  useEffect(() => {
    if (linkData?.title !== "") {
      dispatch(CALC_CLICKS_TODAY({ document: linkData }));
      dispatch(CALC_CLICKS_THIS_MONTH({ document: linkData }));
    }
  }, [dispatch, linkData]);

  return (
    <div>
      <br />
      <h3>Link Analytics ~</h3>

      <p className="--text-sm --mb">
        <span className="link-border">
          <b>Short URL:</b> {domain}/{shortURL}
        </span>

        <span className="link-border">
          <b>Redirects to:</b> {longURL}
        </span>

        <span className="link-border">
          <b>Date created:</b> {createdAt.substring(0, 10)}
        </span>
      </p>
      <hr />
      <br />

      <div className="user-summary">
        <div className="info-summary">
          <InfoBox
            icon={icon1}
            title={"Total Clicks"}
            count={formatNumber.format(clicks)}
            bgColor="dcard1"
          />
          <InfoBox
            icon={icon2}
            title={"Unique Click"}
            // @ts-ignore
            count={formatNumber.format(uniqueClicks)}
            // count={uniqueClicks}
            bgColor="dcard2"
          />

          <InfoBox
            icon={icon3}
            title={"Clicks Today"}
            count={formatNumber.format(clicksToday)}
            bgColor="dcard3"
          />
          <InfoBox
            icon={icon4}
            title={"Clicks This Month"}
            count={formatNumber.format(clicksThisMonth)}
            bgColor="dcard4"
          />
        </div>
      </div>

      <LinkCharts links={links} link={link} alias={alias} />
    </div>
  );
};

export default LinkDetails;
