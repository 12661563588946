import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LinkForm from "../../../components/link/linkForm/LinkForm";
import {
  createShortLink,
  RESET_LINK,
} from "../../../redux/features/link/linkSlice";
import "./CreateLink.scss";
import isUrl from "is-url";
import { getUser } from "../../../redux/features/auth/authSlice";
import { selectVerifiedDomains } from "../../../redux/features/customDomain/domainSlice";

interface LinkProps {
  title: string;
  longURL: string;
  shortURL: string;
  domain: string;
  tag: string;
}

const CreateLink = () => {
  const [linkData, setLinkData] = useState<LinkProps>({
    title: "",
    longURL: "https://",
    shortURL: "",
    domain: "olink.to",
    tag: "",
  });
  const { title, longURL, shortURL, tag, domain } = linkData;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (user === null) {
      // @ts-ignore
      dispatch(getUser());
    }
  }, [dispatch, user]);

  const domains = useSelector(selectVerifiedDomains);
  // console.log(domains);

  // @ts-ignore
  let allDomains = [...new Set(domains)];

  const { isLoading, isSuccess, message } = useSelector(
    (state: any) => state.link
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setLinkData({ ...linkData, [name]: value });
  };

  const createLink = async (e: any) => {
    e.preventDefault();
    if (!linkData.title || !linkData.longURL) {
      return toast.error("Please add title and Long URL");
    }

    const urlIsValid = isUrl(longURL);
    if (!longURL.startsWith("https://")) {
      return toast.error("Please start Long Url with https://");
    }
    if (!urlIsValid) {
      return toast.error("Please enter a valid url");
    }
    const formData = {
      title,
      url: longURL,
      alias: shortURL,
      tag,
      domain,
    };
    // console.log(formData);

    // @ts-ignore
    await dispatch(createShortLink(formData));
  };

  useEffect(() => {
    if (isSuccess && message.includes("ShortLink Created")) {
      navigate("/");
    }
    dispatch(RESET_LINK());
  }, [isSuccess, message, navigate, dispatch]);

  return (
    <div>
      <br />
      <h3>Create ShortLink</h3>
      <div className="--flex-start change-password">
        <LinkForm
          onSubmit={createLink}
          linkData={linkData}
          allDomains={allDomains}
          handleInputChange={handleInputChange}
          isLoading={isLoading}
          btnText={"Create ShortLink"}
        />
      </div>
    </div>
  );
};

export default CreateLink;
