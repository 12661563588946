import { FaTh, FaRegChartBar, FaCommentAlt } from "react-icons/fa";
import { MdAddLink, MdDriveFileRenameOutline } from "react-icons/md";
import { AiFillDollarCircle, AiOutlineEye } from "react-icons/ai";
import { BiEditAlt, BiUserCircle } from "react-icons/bi";
import { RiPagesLine, RiAdvertisementLine } from "react-icons/ri";
import { GrDomain } from "react-icons/gr";

const menu = [
  {
    title: "Dashboard",
    icon: <FaTh />,
    path: "/",
  },
  {
    title: "Shorten Link",
    icon: <MdAddLink />,
    path: "/create-link",
  },
  {
    title: "Manage Ads",
    icon: <RiAdvertisementLine />,
    childrens: [
      {
        icon: <BiEditAlt />,
        title: "Create Ad",
        path: "/create-ad",
      },
      {
        icon: <AiOutlineEye />,
        title: "View Ads",
        path: "/view-ads",
      },
    ],
  },
  {
    title: "Manage Pages",
    icon: <RiPagesLine />,
    childrens: [
      {
        icon: <BiEditAlt />,
        title: "Create Page",
        path: "/create-ad-page",
      },
      {
        icon: <AiOutlineEye />,
        title: "View Pages",
        path: "/view-ad-pages",
      },
    ],
  },
  {
    title: "Custom Domain",
    icon: <MdDriveFileRenameOutline />,
    path: "/custom-domains",
  },
  {
    title: "Account",
    icon: <BiUserCircle />,
    childrens: [
      {
        title: "Profile",
        path: "/profile",
      },
      {
        title: "My Subscription",
        path: "/account",
      },
    ],
  },
  {
    title: "Pricing",
    icon: <AiFillDollarCircle />,
    path: "/pricing",
  },
  {
    title: "Report Bug",
    icon: <FaCommentAlt />,
    path: "/contact-us",
  },
];

export default menu;
