import React from "react";
import "./PriceButton.scss";
import {
  basic_one,
  basic_three,
  basic_two,
  business,
  enterprise,
  free,
  pro,
} from "../../utils";

export const getTheme = (plan) => {
  if (plan === free) {
    return "them0";
  }
  if (plan === basic_one || plan === basic_two || plan === basic_three) {
    return "them0";
  }
  if (plan === pro) {
    return "them1";
  }
  if (plan === business) {
    return "them2";
  }
  if (plan === enterprise) {
    return "them3";
  }
};

const PriceButton = ({ handleCheck, planName, price }) => {
  return (
    <div className="price">
      {/* <form action=""> */}
      <label className="price-card" style={{ width: "250px" }}>
        <input
          name="plan"
          className="radio"
          type="radio"
          value={planName}
          onChange={handleCheck}
        />

        <span className="plan-details">
          <span className={`plan-type`}>
            {planName} - ${price}/month
          </span>
          {planName === free && (
            <>
              <p className="--text-sm">
                - <b>1k</b> Tracked Clicks/month
              </p>
              <p className="--text-sm">- 10 shortLinks, ads & pages</p>
            </>
          )}
          {planName === basic_one && (
            <>
              <p className="--text-sm">
                - <b>10k</b> Tracked Clicks
              </p>
              <p className="--text-sm">- 500 shortLinks, ads & pages</p>
              <p className="--text-sm --color-danger">
                <del> - No Custom Domain</del>
              </p>
            </>
          )}
          {planName === basic_two && (
            <>
              <p className="--text-sm">
                - <b>50k</b> Tracked Clicks
              </p>
              <p className="--text-sm">- 2,000 shortLinks, ads & pages</p>
            </>
          )}
          {planName === basic_three && (
            <>
              <p className="--text-sm">
                - <b>100k</b> Tracked Clicks
              </p>
              <p className="--text-sm">- 5,000 shortLinks, ads & pages</p>
            </>
          )}
          {planName === pro && (
            <>
              <p className="--text-sm">
                - <b>250k</b> Tracked Clicks
              </p>
              <p className="--text-sm">- Unlimited shortLinks, ads & pages</p>
            </>
          )}
          {planName === business && (
            <>
              <p className="--text-sm">
                - <b>500k</b> Tracked Clicks
              </p>
              <p className="--text-sm">- Unlimited shortLinks, ads & pages</p>
            </>
          )}
          {planName === enterprise && (
            <>
              <p className="--text-sm">
                - <b>1Million</b> Tracked Clicks
              </p>
              <p className="--text-sm">- Unlimited shortLinks, ads & pages</p>
            </>
          )}
        </span>
      </label>
      {/* </form> */}
    </div>
  );
};

export default PriceButton;
