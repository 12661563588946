import React, { useEffect } from "react";
import LinkList from "../../components/link/linkList/LinkList";
import useRedirectLoggedOutUser from "../../customHook/useRedirectLoggedOutUser";
import axios from "axios";
import { BACKEND_URL } from "../../redux/features/link/linkService";

const Home = () => {
  useRedirectLoggedOutUser("/login");

  useEffect(() => {
    const getSubStatus = async () => {
      const { data } = await axios.get(`${BACKEND_URL}/api/subs/getSubStatus`);
      return data.role;
    };

    getSubStatus();
  }, []);

  return (
    <div>
      <br />
      <h3>Dashboard ~ </h3>
      <LinkList />
    </div>
  );
};

export default Home;
