import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Card from "../../components/card/Card";
import fromUnixTime from "date-fns/fromUnixTime";
import "./Account.scss";
import axios from "axios";
import { BACKEND_URL } from "../../redux/features/link/linkService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUser } from "../../redux/features/auth/authSlice";

const Account = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sub, setSub] = useState<any>([]);

  const goPricing = () => {
    navigate("/pricing");
  };

  useEffect(() => {
    // @ts-ignore
    dispatch(getUser());
  }, [dispatch]);

  const { user } = useSelector((state: any) => state.auth);
  let planStartDate = sub.map((subb: any) => {
    return subb.current_period_start;
  });
  var startDate: any = fromUnixTime(planStartDate[0]).toString();
  let planEndDate = sub.map((subb: any) => {
    return subb.current_period_end;
  });
  var endDate: any = fromUnixTime(planEndDate[0]).toString();

  useEffect(() => {
    if (user && user?.subscription) {
      setSub(user?.subscription);
    }
    // console.log(sub);
  }, [user]);

  const manageSubs = async () => {
    const { data } = await axios.get(`${BACKEND_URL}/api/subs/manageSubs`);
    // console.log(data);

    window.open(data);
  };

  return (
    <section>
      <div className="container account">
        <h3>My Account</h3>

        {!sub.length || sub[0].status === "canceled" ? (
          <div>
            <Card cardClass="card --mb">
              <p>Plan: {user.plan}</p>
              <hr />
              <p>Plan: {user.status}</p>
            </Card>
            <h4>No subscription found</h4>
            <button className="--btn --btn-primary" onClick={goPricing}>
              View Pricing
            </button>
          </div>
        ) : (
          <></>
        )}
        {sub.length > 0 && sub[0].status !== "canceled" && (
          <button className="--btn --btn-danger" onClick={manageSubs}>
            Manage Subscription
          </button>
        )}

        <div className="table">
          {sub.length > 0 && (
            <table style={{ zIndex: 1 }}>
              <thead>
                <tr>
                  <th>s/n</th>
                  <th>Plan</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Started</th>
                  <th>End</th>
                </tr>
              </thead>
              <tbody style={{ zIndex: 1 }}>
                {sub &&
                  sub.map((subb: any, index: number) => (
                    <tr key={subb.id}>
                      <td>{index + 1}</td>
                      <td>{subb.plan.nickname}</td>
                      <td>
                        {"$"}
                        {subb.plan.amount / 100}
                      </td>
                      <td>{subb.status}</td>
                      <td>{startDate.substring(0, 16)}</td>
                      <td>{endDate.substring(0, 16)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </section>
  );
};

export default Account;
