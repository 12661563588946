import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { RESET, logout } from "../../redux/features/auth/authSlice";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/config";
import { toast } from "react-toastify";
import { UserName } from "../../pages/profile/Profile";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutUser = async () => {
    // @ts-ignore
    await dispatch(logout());
    dispatch(RESET());
    signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  return (
    <div className="--pad header">
      <div className="--flex-between --flex-dir-column">
        <UserName />
        <div className="--flex-between">
          <Link to="/">
            <p>
              <b>Home</b>
            </p>
          </Link>
          &nbsp; &nbsp;
          <Link to="/account">
            <p className="--color-danger">
              <b>UPGRADE</b>
            </p>
          </Link>
          &nbsp; &nbsp;
          <button className="--btn --btn-red" onClick={logoutUser}>
            Logout
          </button>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default Header;
