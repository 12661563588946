import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const API_URL = `${BACKEND_URL}/api/prod/`;

// Create New Product
const createAd = async (formData) => {
  const response = await axios.post(API_URL + "create", formData);
  return response.data;
};

// Get all Ads
const getAds = async () => {
  const response = await axios.get(API_URL + "getProds");
  return response.data;
};

// Delete a Product
const deleteAd = async (id) => {
  const response = await axios.delete(API_URL + id);
  return response.data.message;
};

// Get Ad
const getAd = async (id) => {
  const response = await axios.get(API_URL + id);
  return response.data;
};

// Update Ad
const updateAd = async (editID, newFormData) => {
  const response = await axios.patch(`${API_URL}${editID}`, newFormData);
  return response.data;
};
// set default Ad
const setDefaultAd = async (id) => {
  const response = await axios.patch(API_URL + "setDefaultProd/" + id);
  return response.data;
};

const adService = {
  createAd,
  getAds,
  deleteAd,
  getAd,
  updateAd,
  setDefaultAd,
};

export default adService;
