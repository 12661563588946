import React from "react";
import Card from "../../card/Card";
import { getCountryName } from "../../../utils";
import { Doughnut } from "react-chartjs-2";

const CountryChart = ({ countries, countryData }: any) => {
  return (
    <Card cardClass="dcard dcard-purple --flex-center --flex-dir-column">
      <Card cardClass="group --width-50">
        <p>
          <b>Country</b>
        </p>
        <hr />
        <p className="--text-sm">
          Countries your link visitors came from <b>~</b>
        </p>
        {countries.map((cRef: any, index: number) => {
          return (
            <p className="--text-sm" key={index}>
              <b>- {getCountryName(cRef.name)} :</b> {cRef.count}
            </p>
          );
        })}
      </Card>
      <div className="--width-50">
        <Doughnut data={countryData} />
      </div>
    </Card>
  );
};

export default CountryChart;
