import axios from "axios";

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const API_URL = `${BACKEND_URL}/api/link/`;

const createShortLink = async (formData) => {
  const response = await axios.post(API_URL + "create", formData);
  return response.data;
};

// Get User Links
const getUserLinks = async () => {
  const response = await axios.get(API_URL + "getUserLinks");
  return response.data;
};

// Get User Links
const getLink = async (alias) => {
  const response = await axios.get(API_URL + "getLink/" + alias);
  return response.data;
};

// Delete Link
const deleteLink = async (alias) => {
  const response = await axios.delete(API_URL + alias);
  return response.data.message;
};

// Edit Link
const updateShortLink = async (alias, formData) => {
  const response = await axios.patch(API_URL + alias, formData);

  return response.data.message;
};

const linkService = {
  createShortLink,
  getUserLinks,
  deleteLink,
  updateShortLink,
  getLink,
};

export default linkService;
