import React, { useEffect, useState } from "react";
import PageMenu from "../../components/pageMenu/PageMenu";
import { useDispatch } from "react-redux";
import "./ViewAdPages.scss";
import { useSelector } from "react-redux";
import Tooltip from "rc-tooltip";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactPaginate from "react-paginate";

import { item1, item2 } from "../createAdPage/CreateAdPage";
import {
  FILTER_PAGES,
  selectFilteredPages,
} from "../../redux/features/filter/filterSlice";
import {
  deleteAdPage,
  getAdPages,
} from "../../redux/features/adsPage/pageSlice";
import { Spinner } from "../../components/loader/Loader";
import Search from "../../components/search/Search";
import { shortenText } from "../../utils";
import { getAds, selectAds } from "../../redux/features/ads/adSlice";
import ClickToCopy from "../../components/clickToCopy/ClickToCopy";
import PageStat from "./PageStat";

export interface pageProp {
  _id?: any;
  title?: string;
  domain?: string;
  link?: string;
  linkText?: string;
  link2?: string;
  linkText2?: string;
  ads?: string;
  shortLink?: string;
  views?: any;
  clicks?: any;
  referrer?: string;
}

const ViewAdPages = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>("");
  const { pages, isLoading, isError, message } = useSelector(
    (state: any) => state.page
  );
  const allAds = useSelector(selectAds);
  const filteredPages = useSelector(selectFilteredPages);

  useEffect(() => {
    // @ts-ignore
    dispatch(getAdPages());
  }, [dispatch]);

  // Begin Pagination
  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filteredPages.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredPages.length / itemsPerPage);

  // Invoke when user click
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % filteredPages.length;
    setItemOffset(newOffset);
  };
  // End Pagination

  const removeAdPage = async (alias: string) => {
    // @ts-ignore
    await dispatch(deleteAdPage(alias));
    // @ts-ignore
    dispatch(getAdPages());
  };

  const confirmDelete = (alias: string | any) => {
    confirmAlert({
      title: "Delete This Ad",
      message: "Are you sure to do delete this Page?",
      buttons: [
        {
          label: "Delete",
          onClick: () => removeAdPage(alias),
        },
        {
          label: "Cancel",
          // onClick: () => alert("Click No"),
        },
      ],
    });
  };

  useEffect(() => {
    dispatch(FILTER_PAGES({ pages, search }));
  }, [dispatch, pages, search]);

  useEffect(() => {
    if (allAds.length === 0) {
      // @ts-ignore
      dispatch(getAds());
    }
  }, [dispatch, allAds]);
  // console.log(pages);

  const getAdName = (id: any) => {
    const thisAd = allAds.find((ad: any) => ad._id === id);
    if (thisAd) {
      return thisAd.title;
    }
    return "...";
  };

  return (
    <div>
      <br />
      <h3>View All Ads</h3>
      <PageMenu link1={item1} link2={item2} />
      <br />

      <div className="view-pages">
        <hr />
        {/* <pre>{JSON.stringify(pages, null, 2)}</pre> */}
        {isLoading && <Spinner />}
        <PageStat />
        <hr />
        <div className="table">
          <div className="--flex-between">
            <div className="sort">
              <div className="--flex-start" style={{ width: "200px" }}>
                <span>
                  <h4
                    style={{ width: "100px" }}
                  >{`Pages~ ${filteredPages.length} `}</h4>
                </span>
              </div>
            </div>
            <span>
              <Search
                value={search}
                placeholder="Search Pages"
                onChange={(e: any) => setSearch(e.target.value)}
              />
            </span>
          </div>
          {/* Table */}
          {!isLoading && pages.length === 0 ? (
            <p>No Page found...</p>
          ) : (
            <table style={{ zIndex: 1 }}>
              <thead>
                <tr>
                  <th>s/n</th>
                  <th>Title</th>
                  <th>Ad</th>
                  <th>ShortLink</th>
                  <th>Page Views</th>
                  <th>Ad Clicks</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody style={{ zIndex: 1 }}>
                {currentItems.map((page: object, index: number) => {
                  const {
                    _id,
                    title,
                    ads,
                    shortLink,
                    views,
                    clicks,
                    domain,
                  }: pageProp = page;

                  return (
                    <tr key={_id}>
                      <td>{index + 1}</td>
                      <td>{shortenText(title, 20)}</td>
                      <td>{allAds.length > 0 && getAdName(ads)}</td>

                      <td className="copy-link">
                        <ClickToCopy
                          text={`https://${domain}/p/${shortLink}`}
                        />

                        <span>
                          {domain}
                          /p/{shortenText(shortLink, 10)}
                        </span>
                      </td>

                      <td>{views}</td>

                      <td>{clicks}</td>

                      <td className="icons">
                        <span>
                          <Tooltip
                            placement="top"
                            trigger={["hover"]}
                            overlay={<span>Edit Page</span>}
                          >
                            <Link to={`/create-ad-page?edit=${_id}`}>
                              <FaEdit size={20} color="green" />
                            </Link>
                          </Tooltip>
                        </span>
                        <span>
                          <Tooltip
                            placement="top"
                            trigger={["hover"]}
                            overlay={<span>Delete Page</span>}
                          >
                            <FaTrashAlt
                              size={20}
                              color="red"
                              onClick={() => confirmDelete(_id)}
                            />
                          </Tooltip>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <hr />
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="Prev"
          // @ts-ignore
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="activePage"
        />
      </div>
    </div>
  );
};

export default ViewAdPages;
