import React, { useEffect } from "react";
import "./ViewCustomDomains.scss";
import { Link, NavLink } from "react-router-dom";
import PageMenu from "../../components/pageMenu/PageMenu";
import { useDispatch } from "react-redux";
import {
  RESET_DOMAIN,
  deleteDomain,
  getDomains,
} from "../../redux/features/customDomain/domainSlice";
import { useSelector } from "react-redux";
import { Spinner } from "../../components/loader/Loader";
import Tooltip from "rc-tooltip";
import { FaTrashAlt } from "react-icons/fa";
import { FiRefreshCcw } from "react-icons/fi";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const activeLink = ({ isActive }) => (isActive ? "activePage" : "nothing");

export const item1 = (
  <NavLink to="/custom-domains" className={activeLink}>
    Add Domain
  </NavLink>
);
export const item2 = (
  <NavLink to="/view-custom-domains" className={activeLink}>
    View Domains
  </NavLink>
);

const ViewCustomDomains = () => {
  const dispatch = useDispatch();
  const { isLoading, isSuccess, message, domains } = useSelector(
    (state) => state.domain
  );

  useEffect(() => {
    dispatch(getDomains());
    dispatch(RESET_DOMAIN());
  }, [dispatch]);

  const refreshPage = () => {
    window.location.reload();
  };

  const removeDomain = async (alias) => {
    await dispatch(deleteDomain(alias));
    // @ts-ignore
    dispatch(getDomains());
    dispatch(RESET_DOMAIN());
  };

  const confirmDelete = (alias) => {
    confirmAlert({
      title: "Delete This Domain",
      message:
        "Are you sure to do delete this domain? All links associated with the domain will be unavailable. Proceed with caution!!!",
      buttons: [
        {
          label: "Delete",
          onClick: () => removeDomain(alias),
        },
        {
          label: "Cancel",
          // onClick: () => alert("Click No"),
        },
      ],
    });
  };

  return (
    <div>
      <br />
      <h3>View Domains</h3>
      <PageMenu link1={item1} link2={item2} />
      <br />
      <div className="view-domains">
        {isLoading && <Spinner />}
        <div className="table">
          {/* Table */}
          {!isLoading && domains.length === 0 ? (
            <p>
              No Domain found...{" "}
              <Link to="/custom-domains" className="--color-primary">
                Add Domain
              </Link>
            </p>
          ) : (
            <table style={{ zIndex: 1 }}>
              <thead>
                <tr>
                  <th>s/n</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Created on</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody style={{ zIndex: 1 }}>
                {domains.map((domain, index) => {
                  const { customDomain } = domain;
                  const {
                    id,
                    name,
                    domainType,
                    createdAt,
                    verificationStatus,
                  } = customDomain;

                  return (
                    <tr key={id}>
                      <td>{index + 1}</td>
                      <td>{name || ""}</td>
                      <td>{domainType || ""}</td>
                      <td>{createdAt.substring(0, 10) || ""}</td>
                      <td
                        className={
                          verificationStatus === "verified"
                            ? "verified"
                            : "unverified"
                        }
                      >
                        {verificationStatus || ""}
                      </td>

                      <td className="icons">
                        <span>
                          <Tooltip
                            placement="top"
                            trigger={["hover"]}
                            overlay={<span>Verify Domain</span>}
                          >
                            <FiRefreshCcw
                              size={20}
                              color="green"
                              onClick={() => refreshPage()}
                            />
                          </Tooltip>
                        </span>
                        <span>
                          <Tooltip
                            placement="top"
                            trigger={["hover"]}
                            overlay={<span>Delete Domain</span>}
                          >
                            <FaTrashAlt
                              size={20}
                              color="red"
                              onClick={() => confirmDelete(name)}
                            />
                          </Tooltip>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <hr />
        </div>
      </div>
    </div>
  );
};

export default ViewCustomDomains;
