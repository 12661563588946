import { useEffect } from "react";
import { Link } from "react-router-dom";
import Confetti from "react-confetti";
import useRedirectLoggedOutUser from "../../customHook/useRedirectLoggedOutUser";
import axios from "axios";
import { BACKEND_URL } from "../../redux/features/link/linkService";
import { toast } from "react-toastify";

const Success = () => {
  useRedirectLoggedOutUser("/login");
  useEffect(() => {
    const getSubStatus = async () => {
      try {
        const { data } = await axios.get(
          `${BACKEND_URL}/api/subs/getSubStatus`
        );
        console.log(data.role);
      } catch (error) {
        toast.error("Error updating subscription");
      }
    };

    getSubStatus();
  }, []);
  return (
    <>
      <Confetti />
      <section>
        <div className="container --center-all">
          <h2>Congratulations!!!</h2>
          <p>Your payment was successful...</p>
          <br />
          <button className="--btn --btn-primary">
            <Link to={"/account"}>View Subscription</Link>
          </button>
        </div>
      </section>
    </>
  );
};

export default Success;
