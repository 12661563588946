import { BiSearch } from "react-icons/bi";
import "./Search.scss";

interface Props {
  value: string;
  onChange: any;
  placeholder: string;
}

const Search = ({ value, onChange, placeholder }: Props) => {
  return (
    <div className="search">
      <BiSearch size={18} className="icon" />
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default Search;
