import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import PageMenu from "../../components/pageMenu/PageMenu";
import "./CreateAdPage.scss";
import Card from "../../components/card/Card";
import { Spinner } from "../../components/loader/Loader";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAds } from "../../redux/features/ads/adSlice";
import { FaLink } from "react-icons/fa";
import { GiFishingHook } from "react-icons/gi";
import { toast } from "react-toastify";
import {
  RESET_PAGE,
  createAdPage,
  getAdPage,
  updateAdPage,
} from "../../redux/features/adsPage/pageSlice";
import isUrl from "is-url";
import { selectVerifiedDomains } from "../../redux/features/customDomain/domainSlice";

const activeLink = ({ isActive }: any) => (isActive ? "activePage" : "nothing");

export const item1 = (
  <NavLink to="/create-ad-page" className={activeLink}>
    Create Ad Page
  </NavLink>
);
export const item2 = (
  <NavLink to="/view-ad-pages" className={activeLink}>
    View Ad Pages
  </NavLink>
);

interface formProps {
  title: string;
  ad: string;
  link: any;
  linkText: string;
  link2?: any;
  linkText2?: string;
  shortLink?: string;
  domain?: string;
}

const initialState = {
  title: "",
  ad: "",
  link: "",
  linkText: "",
  link2: "",
  linkText2: "",
  shortLink: "",
  domain: "olink.to",
};

const CreateAdPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anotherLink, setAnotherLink] = useState(false);
  const { ads } = useSelector((state: any) => state.ads);
  // const [adID, setAdID] = useState(ads[0]?._id);
  const [adID, setAdID] = useState("-- Select Ad --");
  const [selectedAd, setSelectedAd] = useState<any>(null);
  const [formData, setFormData] = useState<formProps>(initialState);
  const { title, ad, domain, link, linkText, link2, linkText2, shortLink } =
    formData;

  const { page, pages, isLoading, isError, message, isSuccess } = useSelector(
    (state: any) => state.page
  );

  const [urlParams] = useSearchParams();
  const editID = urlParams.get("edit");

  useEffect(() => {
    if (ads.length === 0) {
      // @ts-ignore
      dispatch(getAds());
    }
    if (pages.length === 0 && editID) {
      // @ts-ignore
      dispatch(getAdPage(editID));
    }
  }, [dispatch, ads, pages, editID]);

  const newAdsArr = ads.map((ad: any) => {
    //   @ts-ignore
    return { title: ad.title, _id: ad._id };
  });

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const savePage = async (e: any) => {
    e.preventDefault();
    if (!title || !link || !linkText || !adID) {
      return toast.error("Please fill in all fields");
    }
    // const urlIsValid = validateUrl(link);
    const urlIsValid = isUrl(link);
    if (!link.startsWith("https://")) {
      return toast.error("Please start Link 1 with https://");
    }
    if (!urlIsValid) {
      return toast.error("Please enter a valid url");
    }
    if (link2) {
      // const urlIsValid2 = validateUrl(link2);
      const urlIsValid2 = isUrl(link2);
      if (!link2.startsWith("https://")) {
        return toast.error("Please start Link 2 with https://");
      }
      if (!urlIsValid2) {
        return toast.error("Please enter a valid second url");
      }
    }
    if ((link2 && !linkText2) || (linkText2 && !link2)) {
      return toast.error("Please enter second link and link Text");
    }
    if (!adID || adID === "-- Select Ad --") {
      return toast.error("Please select an ad");
    }

    const newFormData = {
      title,
      link,
      linkText,
      link2,
      linkText2,
      ads: adID,
      alias: shortLink,
      domain: domain,
    };
    // console.log(newFormData);

    if (editID) {
      // @ts-ignore
      await dispatch(updateAdPage({ editID, newFormData }));
    } else {
      // @ts-ignore
      dispatch(createAdPage(newFormData));
    }
  };

  useEffect(() => {
    if (isSuccess && message === "Ads Page Created") {
      navigate("/view-ad-pages");
    }

    dispatch(RESET_PAGE());
  }, [isSuccess, message, dispatch, navigate]);

  // If user clicks edit icon
  useEffect(() => {
    if (editID && pages.length > 0) {
      const editPage = pages.find((page: any) => {
        return page._id === editID;
      });
      // console.log(editPage);

      if (editPage && editPage.link2 !== "") {
        setAnotherLink(true);
      }
      setFormData({
        ...editPage,
      });
      setAdID(editPage.ads);
    }
  }, [editID, pages]);

  useEffect(() => {
    if (ads.length > 0 && editID && adID !== "-- Select Ad --") {
      const thisAd = ads.find((_ad: any) => {
        return _ad._id.toString() === adID.toString();
      });

      if (thisAd) {
        setSelectedAd(thisAd);
      }
    }
    if (editID === null && adID !== "-- Select Ad --") {
      const _ad = ads.find((ad: any) => {
        return ad._id === adID;
      });
      // console.log(_ad);
      if (_ad) {
        setSelectedAd(_ad);
      }
    }
  }, [ads, adID, editID]);

  // If user refreshes the page
  useEffect(() => {
    if (editID && pages.length === 0) {
      if (page && page.link2 !== "") {
        setAnotherLink(true);
      }
      if (page !== null) {
        setFormData({
          ...page,
        });
        setAdID(page.ads);
        setSelectedAd(page);
      }
    }
  }, [editID, page, pages, dispatch]);

  const domains = useSelector(selectVerifiedDomains);
  // @ts-ignore
  let allDomains = [...new Set(domains)];

  return (
    <div className="create-page">
      <br />
      <h3>Create Ad Page</h3>

      <PageMenu link1={item1} link2={item2} />
      <br />
      <div className="flex-between --flex-dir-column">
        <Card cardClass={"card-adForm "}>
          <form onSubmit={savePage} className="--form-control">
            <p className="req">
              <input
                ref={inputRef}
                type="text"
                placeholder="Add a title"
                required
                name="title"
                value={title}
                onChange={handleInputChange}
              />
            </p>
            <hr />
            <p className="req">
              <label>Short URL (optional)</label>
              <span className="--text-sm">Custom domains coming soon.</span>
              <span className="--flex-end">
                <select
                  className="url-prefix"
                  name="domain"
                  value={domain}
                  onChange={handleInputChange}
                >
                  {allDomains.map((domain: any, index: any) => {
                    return (
                      <option key={index} value={domain}>
                        {domain}
                      </option>
                    );
                  })}
                </select>
                {"/p/"}
                <input
                  type="text"
                  placeholder="alias"
                  name="shortLink"
                  value={shortLink}
                  onChange={handleInputChange}
                  disabled={editID ? true : false}
                />
              </span>
            </p>
            <hr />

            <p className="req">
              <label>Link 1</label>
              <input
                type="text"
                placeholder="https://"
                required
                name="link"
                value={link}
                onChange={handleInputChange}
              />
            </p>
            <p className="req">
              <input
                type="text"
                placeholder="Link Text"
                required
                name="linkText"
                value={linkText}
                onChange={handleInputChange}
              />
            </p>
            <hr />
            {!anotherLink && (
              <span className="addLink" onClick={() => setAnotherLink(true)}>
                + Add Link 2
              </span>
            )}
            {anotherLink && (
              <>
                <p>
                  <label>Link 2 (optional)</label>
                  <input
                    type="text"
                    placeholder="https://"
                    // required
                    name="link2"
                    value={link2}
                    onChange={handleInputChange}
                  />
                </p>
                <p>
                  <input
                    type="text"
                    placeholder="Link Text 2"
                    // required
                    name="linkText2"
                    value={linkText2}
                    onChange={handleInputChange}
                  />
                </p>
              </>
            )}
            <hr />
            <p className="req">
              <label>Select Ad:</label>
              <select
                value={adID}
                onChange={(e: any) => setAdID(e.target.value)}
              >
                <option value="-- Select Ad --" disabled>
                  -- Select Ad --
                </option>
                {/* @ts-ignore */}
                {newAdsArr.map((myAd, index) => {
                  return (
                    <option key={index} value={myAd?._id}>
                      {myAd?.title}
                    </option>
                  );
                })}
              </select>
            </p>

            <div>
              {isLoading ? (
                <Spinner />
              ) : (
                <button type="submit" className="--btn --btn-primary">
                  {editID ? "Update Page" : "Create Page"}
                </button>
              )}
            </div>
          </form>
        </Card>
        <div>
          {title && <h4>~ Ad Page Title: {title}</h4>}
          <Card cardClass="ad-preview">
            <div className="ad-head container --flex-between">
              <div className="logo">
                <FaLink size={25} color="#fff" style={{ cursor: "pointer" }} />
                <p className="logo-text">
                  Link<span>ito</span>
                </p>
              </div>
              <p className="--text-light">Home</p>
            </div>
            <section>
              <div className=" --flex-center --mb2">
                {linkText && (
                  <button
                    onClick={() => window.open(link)}
                    className="--btn --btn-primary"
                  >
                    {linkText}
                  </button>
                )}
                &nbsp; &nbsp; &nbsp;
                {linkText2 && (
                  <button
                    onClick={() => window.open(link2)}
                    className="--btn --btn-danger"
                  >
                    {linkText2}
                  </button>
                )}
              </div>

              <div className="--text-center">
                <GiFishingHook size={35} color="purple" />
              </div>
              <div className="ad --flex-center">
                {ads.length > 0 && selectedAd !== null ? (
                  <Card cardClass={"ad-details"}>
                    <div className="image-preview">
                      <img src={selectedAd?.image} alt="adImage" />
                      {/* @ts-ignore */}
                      {selectedAd?.price > 0 && (
                        <span className="price">${selectedAd?.price}</span>
                      )}
                      {/* @ts-ignore */}
                      {selectedAd?.salePrice > 0 && (
                        <span className="salePrice">
                          ${selectedAd?.salePrice}
                        </span>
                      )}
                      {selectedAd?.coupon && (
                        <span className="coupon">
                          COUPON: {selectedAd?.coupon}
                        </span>
                      )}
                    </div>

                    <h3>{selectedAd?.heading}</h3>
                    <p className="desc">{selectedAd?.desc}</p>
                    {selectedAd?.linkText && (
                      <button
                        className="--btn --btn-primary --btn-lg --btn-block"
                        onClick={() => window.open(selectedAd?.link)}
                      >
                        {selectedAd?.linkText}
                      </button>
                    )}
                  </Card>
                ) : (
                  <p>No ads founds...</p>
                )}
              </div>
            </section>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CreateAdPage;
