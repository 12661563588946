import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: "link-manager--io.firebaseapp.com",
  projectId: "link-manager--io",
  storageBucket: "link-manager--io.appspot.com",
  messagingSenderId: "569199928726",
  appId: "1:569199928726:web:9de12e03e90f8eb6f8a9b7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app;
