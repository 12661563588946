import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const API_URL = `${BACKEND_URL}/api/customDomain/`;

// Create New Domain
const createDomain = async (formData) => {
  const response = await axios.post(API_URL + "create", formData);
  return response.data.message;
};

// Get all Domains
const getDomains = async () => {
  const response = await axios.get(API_URL + "getCustomDomains");
  return response.data;
};

// Get all Domains
const deleteDomain = async (alias) => {
  const response = await axios.delete(API_URL + alias);
  return response.data.message;
};

const domainService = {
  createDomain,
  getDomains,
  deleteDomain,
};

export default domainService;
