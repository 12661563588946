import React, { useEffect, useRef, useState } from "react";
import Card from "../../components/card/Card";
import "./CreateAd.scss";
import { shortenText, validateUrl } from "../../utils";
import PageMenu from "../../components/pageMenu/PageMenu";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  RESET_AD,
  createAd,
  getAd,
  updateAd,
} from "./../../redux/features/ads/adSlice";
import { Spinner } from "../../components/loader/Loader";
import isUrl from "is-url";
import { BsTrash } from "react-icons/bs";

interface formProps {
  title: string;
  heading: string;
  desc: string;
  link: any;
  linkText: string;
  image: string;
  price?: number;
  salePrice?: number;
  coupon?: string;
  countdownDate?: string;
  countdownTime?: string;
}

const activeLink = ({ isActive }: any) => (isActive ? "activePage" : "nothing");

export const item1 = (
  <NavLink to="/create-ad" className={activeLink}>
    Create Ad
  </NavLink>
);
export const item2 = (
  <NavLink to="/view-ads" className={activeLink}>
    View Ads
  </NavLink>
);

const initialState = {
  title: "",
  heading: "",
  desc: "",
  link: "https://",
  linkText: "",
  image: "",
  price: 0,
  salePrice: 0,
  coupon: "",
  countdownDate: "",
  countdownTime: "",
};

const CreateAd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // @ts-ignore
  const [formData, setFormData] = useState<formProps>(initialState);
  const {
    title,
    image,
    heading,
    desc,
    link,
    linkText,
    price,
    salePrice,
    coupon,
    countdownDate,
    countdownTime,
  } = formData;
  const [adImage, setAdImage] = useState<string>("");
  const [imageName, setImageName] = useState<string>("");
  const [imageURL, setImageURL] = useState<string>("");
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const fileInputRef = useRef(null);
  const formRef = React.useRef<HTMLFormElement>(null);
  const [captcha, setCaptcha] = useState("");

  const [urlParams] = useSearchParams();
  const editID = urlParams.get("edit");
  const { ad, ads, isLoading, message, isSuccess } = useSelector(
    (state: any) => state.ads
  );

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const date = new Date();

  const handleImageChange = (e: any) => {
    // console.log(e.target.files[0]);
    if (imageURL !== "") {
      return toast.error(
        "Please delete image below before selecting another one"
      );
    }
    setAdImage(e.target.files[0]);
    setImageName(
      date.getTime().toString() +
        "-" +
        e.target.files[0].name.replaceAll(" ", "_")
    );
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const resetFileInput = () => {
    // @ts-ignore
    fileInputRef.current.value = null;
    setAdImage("");
    setImageName("");
    setImagePreview(null);
  };

  const uploadImage = async (e: any) => {
    e.preventDefault();

    if (imageURL !== "") {
      return toast.info(
        "First Delete the uploaded image below, then upload another"
      );
    }
    const MAX_FILE_SIZE = 524;
    // @ts-ignore
    const fileSizeKiloBytes = adImage.size / 1024;
    if (adImage && fileSizeKiloBytes > MAX_FILE_SIZE) {
      return toast.error("Image size limit of 500kb exceeded.");
    }
    if (
      adImage &&
      // @ts-ignore
      (adImage.type === "image/jpeg" ||
        // @ts-ignore
        adImage.type === "image/jpg" ||
        // @ts-ignore
        adImage.type === "image/png")
    ) {
      const options = {
        method: "PUT",
        headers: {
          AccessKey: process.env.REACT_APP_BUNNY_KEY,
          "content-type": "application/octet-stream",
        },
        body: adImage,
      };
      // @ts-ignore
      fetch(`https://storage.bunnycdn.com/linkito/ads/${imageName}`, options)
        .then((response) => response.json())
        .then((response) => {
          if (response.Message === "File uploaded.") {
            toast.success("Image Uploaded");
            setImageURL(`https://linkito.b-cdn.net/ads/${imageName}`);
          }
        })
        .catch((err) => console.error(err));
    } else {
      return toast.info("Only image format jpg, gpeg and png are allowed");
    }
  };

  const deleteImage = async () => {
    const options = {
      method: "DELETE",
      headers: { AccessKey: process.env.REACT_APP_BUNNY_KEY },
    };
    // @ts-ignore
    fetch(`https://storage.bunnycdn.com/linkito/ads/${imageName}`, options)
      .then((response) => response.json())
      .then((response) => {
        if (response.Message === "File deleted successfuly.") {
          toast.success("Image Deleted");
          setImageURL("");
          setImagePreview(null);
        }
      })
      .catch((err) => console.error(err));
  };

  const clearDate = () => {
    setFormData({ ...formData, countdownDate: "", countdownTime: "" });
  };

  // Create Ad
  const saveAd = async (e: any) => {
    e.preventDefault();
    if (imageURL === "") {
      return toast.error("Please upload image before subitting form.");
    }
    if (!title || !heading || !desc || !link || !linkText) {
      return toast.error("Please fill in all required fields");
    }
    if (!adImage && imagePreview === null) {
      return toast.error("Please add an image");
    }
    // const urlIsValid = validateUrl(link);
    const urlIsValid = isUrl(link);
    if (!link.startsWith("https://")) {
      return toast.error("Please start Link with https://");
    }
    if (!urlIsValid) {
      return toast.error("Please enter a valid url");
    }
    // @ts-ignore
    if (price > 0 && salePrice < 1) {
      return toast.error("Price should be greater than sale price");
    }

    setFormLoading(true);
    const newFormData = {
      ...formData,
      image: imageURL,
    };

    if (editID) {
      // @ts-ignore
      await dispatch(updateAd({ editID, newFormData }));
    } else {
      // @ts-ignore
      await dispatch(createAd(newFormData));
    }
    setFormLoading(false);
  };

  useEffect(() => {
    if (isSuccess && message.includes("Ads Created")) {
      navigate("/view-ads");
    }
    dispatch(RESET_AD());
  }, [isSuccess, message, navigate, dispatch]);

  // If user clicks edit icon
  // useEffect(() => {
  //   if (editID && ads.length > 0) {
  //     const editAD = ads.find((ad: any) => {
  //       return ad._id === editID;
  //     });
  //     setFormData({
  //       title: editAD?.title || "",
  //       heading: editAD?.heading || "",
  //       desc: editAD?.desc || "",
  //       link: editAD?.link || "https://",
  //       linkText: editAD?.linkText || "",
  //       image: editAD?.image || "",
  //       price: editAD?.price || 0,
  //       salePrice: editAD?.salePrice || 0,
  //       coupon: editAD?.coupon || "",
  //       countdownDate: editAD?.countdownDate || "",
  //       countdownTime: editAD?.countdownTime || "",
  //     });
  //     setImagePreview(editAD?.image);
  //   }
  // }, [editID, ads]);

  // If user refreshes the page
  useEffect(() => {
    if (editID) {
      // @ts-ignore
      dispatch(getAd(editID));
    }
  }, [editID, ads, dispatch]);

  useEffect(() => {
    if (ad !== null) {
      setFormData({
        title: ad.title || "",
        heading: ad.heading || "",
        desc: ad.desc || "",
        link: ad.link || "https://",
        linkText: ad.linkText || "",
        image: ad.image || "",
        price: ad.price || 0,
        salePrice: ad.salePrice || 0,
        coupon: ad.coupon || "",
        countdownDate: ad.countdownDate || "",
        countdownTime: ad.countdownTime || "",
      });
      setImagePreview(ad?.image);
      setImageURL(ad.image);
      setImageName(ad.image.replace("https://linkito.b-cdn.net/ads/", ""));
    }
  }, [ad]);

  useEffect(() => {
    if (editID === null || !editID) {
      setFormData(initialState);
      setImagePreview(null);
      setImageURL("");
      setImageName("");
    }
  }, [editID]);

  return (
    <div className="create-ad">
      <br />
      <h3>Create an Ad</h3>

      <PageMenu link1={item1} link2={item2} />
      <br />
      <div className="flex-between --flex-dir-column">
        <Card cardClass={"card-adForm "}>
          <form className="--form-control">
            <p className="req">
              <input
                ref={inputRef}
                type="text"
                placeholder="Add a title"
                required
                name="title"
                value={title}
                onChange={handleInputChange}
              />
            </p>
            <hr />
            <Card cardClass="group">
              <p className="req">
                <label>Photo:</label>
                <code className="--text-sm --color-dark">
                  File type: jpg, png | Max size: 500kb
                </code>
                <input
                  type="file"
                  accept="image/png , image/jpeg, image/jpg"
                  name="image"
                  ref={fileInputRef}
                  onChange={handleImageChange}
                  disabled={imageURL === "" ? false : true}
                />
              </p>
              {adImage && imageURL === "" ? (
                <span className="--flex-start">
                  <button
                    className="--btn --btn-danger --text-sm"
                    onClick={resetFileInput}
                  >
                    Remove Image
                  </button>
                  <button className="--btn --btn-primary" onClick={uploadImage}>
                    Upload Image
                  </button>
                </span>
              ) : (
                <></>
              )}
              {imageURL !== "" && (
                <p className="--text-sm">
                  To add a new image, first delete the one below
                </p>
              )}
              {imageURL && (
                <span
                  className="--flex-between --mt"
                  style={{ border: "1px solid grey" }}
                >
                  <img src={imageURL} alt="adImage" width="100px" />
                  <BsTrash
                    color="red"
                    size={"25"}
                    onClick={deleteImage}
                    style={{ cursor: "pointer" }}
                  />
                </span>
              )}
            </Card>
            <p className="req">
              <input
                type="text"
                placeholder="Add a heading"
                required
                name="heading"
                value={heading}
                onChange={handleInputChange}
                maxLength={50}
              />
            </p>
            <p className="req">
              <textarea
                name="desc"
                value={desc}
                onChange={handleInputChange}
                required
                // @ts-ignore
                cols="10"
                // @ts-ignore
                rows="3"
                maxLength={200}
              >
                Short Description...
              </textarea>
            </p>
            <p className="req">
              <label>Destination Link:</label>
              <input
                type="text"
                placeholder="https://"
                required
                name="link"
                value={link}
                onChange={handleInputChange}
              />
            </p>
            <p className="req">
              <input
                type="text"
                placeholder="Link Text"
                required
                name="linkText"
                value={linkText}
                onChange={handleInputChange}
              />
            </p>
            <p>
              <label>Price</label>
              <input
                type="number"
                placeholder="Price"
                required
                name="price"
                value={price}
                onChange={handleInputChange}
              />
            </p>
            <p>
              <label>Sale Price</label>
              <input
                type="number"
                placeholder="Sale Price"
                required
                name="salePrice"
                value={salePrice}
                onChange={handleInputChange}
              />
            </p>
            <p>
              <label>Coupon</label>
              <input
                type="text"
                placeholder="Coupon"
                // required
                name="coupon"
                value={coupon}
                onChange={handleInputChange}
                maxLength={10}
              />
            </p>
            <hr />
            <p>
              <b>Opional:</b> Add a countdown to prompt users to make a purchase
              on time.
            </p>
            <Card cardClass={"group"}>
              <p className="--mb">
                <label>Countdown date: </label>
                <input
                  type="date"
                  name="countdownDate"
                  value={countdownDate}
                  onChange={handleInputChange}
                />
              </p>

              <p className="--mb">
                <label>Countdown time: </label>
                <input
                  type="time"
                  name="countdownTime"
                  value={countdownTime}
                  onChange={handleInputChange}
                />
              </p>
              <button className="--btn --btn-danger" onClick={clearDate}>
                clearDate
              </button>
            </Card>

            <div>
              {formLoading ? (
                <Spinner />
              ) : (
                <button
                  type="submit"
                  className="--btn --btn-primary"
                  onClick={saveAd}
                >
                  {editID ? "Update Ad" : "Create Ad"}
                </button>
              )}
            </div>
          </form>
        </Card>
        <div>
          {title && <h4>~ Ad Title: {title}</h4>}

          <Card cardClass={"card ad-details"}>
            {imagePreview != null ? (
              <div className="image-preview">
                <img src={imagePreview} alt="product" />
                {/* @ts-ignore */}
                {price > 0 && <span className="price">${price}</span>}
                {/* @ts-ignore */}
                {salePrice > 0 && (
                  <span className="salePrice">${salePrice}</span>
                )}
                {coupon && <span className="coupon">COUPON: {coupon}</span>}
              </div>
            ) : (
              <p>No image set for this ad.</p>
            )}
            <h3>{heading}</h3>
            <p className="desc">{desc}</p>
            {linkText && (
              <button
                className="--btn --btn-primary --btn-lg --btn-block"
                // @ts-ignore
                onClick={() => window.open({ link })}
              >
                {linkText}
              </button>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CreateAd;
