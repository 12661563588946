import React from "react";
import styles from "./Card.module.scss";

interface Props {
  children: any;
  cardClass?: string;
}

const Card = ({ children, cardClass }: Props) => {
  return <div className={`${styles.card} ${cardClass}`}>{children}</div>;
};

export default Card;
