import { useSelector } from "react-redux";
import "./Sub.css";
import { useNavigate } from "react-router-dom";

interface Props {
  plan?: any;
  price?: any;
  theme?: any;
  free?: any;
  basic_one?: any;
  basic_two?: any;
  basic_three?: any;
  pro?: any;
  business?: any;
  enterprise?: any;
  yearly?: any;
  onBuy?: any;
}

const Sub = ({
  plan,
  price,
  theme,
  free,
  basic_one,
  basic_two,
  basic_three,
  pro,
  business,
  enterprise,
  yearly,
  onBuy,
}: Props) => {
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.auth);

  return (
    <div className="price-box --card">
      <div className={`box --p2 ${theme}`}>
        <p className="plan">{plan}</p>
        <h4 className="--text-light">
          <span>$</span>
          <span className="basic">{price}</span>
          {yearly && (
            <p className="--text-light">
              <del> 30% Off </del>
            </p>
          )}
        </h4>
      </div>
      <div className="features">
        <ul>
          {free && <li>1,000 tracked clicks / month</li>}
          {free && <li>Unlimited Redirects</li>}
          {free && <li>10 Links</li>}
          {free && <li>10 Ads</li>}
          {free && <li>10 Pages</li>}

          {basic_one && <li>10,000 tracked clicks / month</li>}
          {basic_one && <li>Unlimited Redirects</li>}
          {basic_one && <li>500 Links</li>}
          {basic_one && <li>500 Ads</li>}
          {basic_one && <li>500 Pages</li>}
          {basic_one && (
            <li>
              <del className="--color-danger">No Custom Domain</del>
            </li>
          )}

          {basic_two && <li>50,000 tracked clicks / month</li>}
          {basic_two && <li>Unlimited Redirects</li>}
          {basic_two && <li>2,000 Links</li>}
          {basic_two && <li>2,000 Ads</li>}
          {basic_two && <li>2,000 Pages</li>}
          {basic_two && <li>1 Custom Domain</li>}

          {basic_three && <li>100,000 tracked clicks / month</li>}
          {basic_three && <li>Unlimited Redirects</li>}
          {basic_three && <li>5,000 Links</li>}
          {basic_three && <li>5,000 Ads</li>}
          {basic_three && <li>5,000 Pages</li>}
          {basic_three && <li>1 Custom Domain</li>}

          {pro && <li>250,000 tracked clicks / month</li>}
          {pro && <li>Unlimited Redirects</li>}
          {pro && <li>Unlimited Links</li>}
          {pro && <li>Unlimited Ads</li>}
          {pro && <li>Unlimited Pages</li>}
          {pro && <li>5 Custom Domain</li>}

          {business && <li>500,000 tracked clicks / month</li>}
          {business && <li>Unlimited Redirects</li>}
          {business && <li>Unlimited Links</li>}
          {business && <li>Unlimited Ads</li>}
          {business && <li>Unlimited Pages</li>}
          {business && <li>10 Custom Domain</li>}

          {enterprise && <li>1,000,000 tracked clicks / month</li>}
          {enterprise && <li>Unlimited Redirects</li>}
          {enterprise && <li>Unlimited Links</li>}
          {enterprise && <li>Unlimited Ads</li>}
          {enterprise && <li>Unlimited Pages</li>}
          {enterprise && <li>25 Custom Domain</li>}
        </ul>
        {/* <button className={`btn ${theme}`} onClick={onBuy}>
          Buy Now
        </button> */}
        {user && user?.plan === "Free" && (
          <button className={`btn ${theme}`} onClick={onBuy}>
            Subscribe Now
          </button>
        )}
        {user && user?.plan !== "Free" && user?.status === "canceled" && (
          <button className={`btn ${theme}`} onClick={onBuy}>
            Subscribe Now
          </button>
        )}
        {user && user?.plan !== "Free" && user?.status !== "canceled" && (
          <button
            className={`btn ${theme}`}
            onClick={() => navigate("/account")}
          >
            Manage Subscription
          </button>
        )}
      </div>
    </div>
  );
};

export default Sub;
