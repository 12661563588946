import { useEffect } from "react";
import InfoBox from "../../components/infoBox/InfoBox";
import { formatNumbers } from "./../../components/userStats/LinkStats";
import { BiLink } from "react-icons/bi";
import { HiOutlineCursorClick } from "react-icons/hi";
import { TbClick } from "react-icons/tb";
import { MdAdsClick } from "react-icons/md";
import { useSelector } from "react-redux";
import "./PageStat.scss";
import { useDispatch } from "react-redux";
import {
  CALC_AD_CLICKS,
  CALC_PAGE_VIEWS,
} from "../../redux/features/adsPage/pageSlice";

// Icons
const icon1 = <BiLink size={40} color="#fff" />;
const icon2 = <HiOutlineCursorClick size={40} color="#fff" />;
const icon3 = <TbClick size={40} color="#fff" />;
const icon4 = <MdAdsClick size={40} color="#fff" />;

const PageStat = () => {
  const dispatch = useDispatch();
  const { pages, pageViews, prodClicks, isLoading, isError, message } =
    useSelector((state) => state.page);

  useEffect(() => {
    dispatch(CALC_PAGE_VIEWS());
    dispatch(CALC_AD_CLICKS());
  }, [dispatch, pages]);

  return (
    <div className="user-summary">
      <div className="info-summary">
        <InfoBox
          icon={icon1}
          title={"Total Pages"}
          count={formatNumbers(pages.length)}
          bgColor="ccard1"
          textColor="--color-white"
        />
        <InfoBox
          icon={icon2}
          title={"Page Views"}
          count={formatNumbers(pageViews)}
          bgColor="ccard2"
          textColor="--color-white"
        />

        <InfoBox
          icon={icon3}
          title={"Ad Clicks"}
          count={formatNumbers(prodClicks)}
          bgColor="ccard3"
          textColor="--color-white"
        />
        {/* <InfoBox
          icon={icon4}
          title={"Clicks This Year"}
          count={formatNumbers(clicksPerYear)}
          bgColor="ccard4"
          textColor="--color-white"
        /> */}
      </div>
    </div>
  );
};

export default PageStat;
