import "./InfoBox.scss";

interface InfoProps {
  bgColor: string;
  textColor?: string;
  title: string;
  count: any;
  icon: any;
}

const InfoBox = ({ bgColor, textColor, title, count, icon }: InfoProps) => {
  return (
    <div className={`info-box ${bgColor}`}>
      <span className="info-icon --color-white">{icon}</span>
      <span>
        <p className={`info-text ${textColor}`}>{title}</p>
        <h4 className={`info-text ${textColor}`}>{count}</h4>
      </span>
    </div>
  );
};

export default InfoBox;
