import React, { useRef, useEffect } from "react";
import Card from "../../card/Card";
import { Spinner } from "../../loader/Loader";
import { Link } from "react-router-dom";

interface Props {
  onSubmit?: any;
  // ref?: any;
  linkData?: any;
  allDomains?: any;
  handleInputChange?: any;
  isLoading?: boolean | any;
  btnText?: string | any;
}

const LinkForm = ({
  onSubmit,
  // ref,
  linkData,
  allDomains,
  handleInputChange,
  isLoading,
  btnText,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef.current?.focus();
  }, []);
  const editPage: boolean = window.location.href.includes("edit-link");

  return (
    <Card cardClass={"card"}>
      <>
        <form onSubmit={onSubmit}>
          <p className="req">
            <label>Title</label>
            <input
              ref={inputRef}
              type="text"
              placeholder="Add a title"
              required
              name="title"
              value={linkData?.title}
              onChange={handleInputChange}
            />
          </p>
          <p className="req">
            <label>Long URL</label>
            <span className="--text-sm">
              Must start with <b>https://</b>.
            </span>
            <input
              type="text"
              placeholder="Long URL"
              required
              name="longURL"
              value={linkData?.longURL}
              onChange={handleInputChange}
            />
          </p>
          <p className="req">
            <label>Short URL (optional)</label>
            <span className="--text-sm">
              Select Custom domains. or{" "}
              <Link className="--color-primary" to="/custom-domains">
                Add Your Domain
              </Link>
            </span>
            <span className="--flex-end">
              <select
                className="url-prefix"
                name="domain"
                value={linkData?.domain}
                onChange={handleInputChange}
              >
                {allDomains.map((domain: any, index: any) => {
                  return (
                    <option key={index} value={domain}>
                      {domain}
                    </option>
                  );
                })}
              </select>
              {"/ "}
              {/* <input
                className="url-prefix"
                type="text"
                value={"zli.to/"}
                disabled
              /> */}
              <input
                type="text"
                placeholder="alias"
                name="shortURL"
                value={linkData?.shortURL}
                onChange={handleInputChange}
                disabled={editPage}
              />
            </span>
          </p>
          <p>
            <label>Tag (Optional)</label>
            <span className="--text-sm">A way to group similar links.</span>
            <input
              type="text"
              placeholder="Tag"
              // required
              name="tag"
              value={linkData?.tag}
              onChange={handleInputChange}
            />
          </p>

          {isLoading ? (
            <Spinner />
          ) : (
            <button type="submit" className="--btn --btn-danger --btn-block">
              {btnText}
            </button>
          )}
        </form>
      </>
    </Card>
  );
};

export default LinkForm;
