import React, { useEffect } from "react";
import { BiLink, BiUserCheck, BiUserMinus, BiUserX } from "react-icons/bi";
import { FaUsers } from "react-icons/fa";
import { TbClick } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import InfoBox from "../infoBox/InfoBox";
import "./LinkStats.scss";
import { HiCursorClick, HiOutlineCursorClick } from "react-icons/hi";
import {
  CALC_CLICKS_PER_DAY,
  CALC_CLICKS_PER_MONTH,
  CALC_CLICKS_PER_YEAR,
} from "../../redux/features/link/linkSlice";
import { MdAdsClick } from "react-icons/md";

export const formatNumbers = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// Icons
const icon1 = <BiLink size={40} color="#fff" />;
const icon2 = <HiOutlineCursorClick size={40} color="#fff" />;
const icon3 = <TbClick size={40} color="#fff" />;
const icon4 = <MdAdsClick size={40} color="#fff" />;

const LinkStats = () => {
  const dispatch = useDispatch();
  const { links, clicksPerDay, clicksPerMonth, clicksPerYear } = useSelector(
    (state) => state.link
  );

  useEffect(() => {
    dispatch(CALC_CLICKS_PER_DAY());
    dispatch(CALC_CLICKS_PER_MONTH());
    dispatch(CALC_CLICKS_PER_YEAR());
  }, [dispatch, links]);

  return (
    <div className="user-summary">
      <div className="info-summary">
        <InfoBox
          icon={icon1}
          title={"Total Links"}
          count={formatNumbers(links.length)}
          bgColor="ccard1"
          textColor="--color-white"
        />
        <InfoBox
          icon={icon2}
          title={"Clicks Today"}
          count={formatNumbers(clicksPerDay)}
          bgColor="ccard2"
          textColor="--color-white"
        />

        <InfoBox
          icon={icon3}
          title={"Clicks this Month"}
          count={formatNumbers(clicksPerMonth)}
          bgColor="ccard3"
          textColor="--color-white"
        />
        <InfoBox
          icon={icon4}
          title={"Clicks This Year"}
          count={formatNumbers(clicksPerYear)}
          bgColor="ccard4"
          textColor="--color-white"
        />
      </div>
    </div>
  );
};

export default LinkStats;
