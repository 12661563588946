import React from "react";
import Card from "../../card/Card";
import { Doughnut } from "react-chartjs-2";
import { formatNumber } from "../../../utils";

const ReferrerChart = ({ linkReferrer, data }: any) => {
  return (
    <Card cardClass="dcard dcard-purple --flex-center --flex-dir-column">
      <Card cardClass="group --width-50">
        <p>
          <b>Referrer</b>
        </p>
        <hr />
        <p className="--text-sm">
          Websites your link visitors came from <b>~</b>
        </p>
        {linkReferrer.map((lRef: any, index: number) => {
          return (
            <p className="--text-sm" key={index}>
              <b>- {lRef.source} :</b> {formatNumber.format(lRef.count)}
            </p>
          );
        })}
      </Card>
      <div className="--width-50">
        <Doughnut data={data} />
      </div>
    </Card>
  );
};

export default ReferrerChart;
