import React from "react";
import { Link } from "react-router-dom";
import useRedirectLoggedOutUser from "../../customHook/useRedirectLoggedOutUser";

const Cancel = () => {
  useRedirectLoggedOutUser("/login");
  return (
    <>
      <section>
        <div className="container --center-all">
          <h2>Payment Calcelled!!!</h2>
          <p>Looks like your payment was cancelled...</p>
          <br />
          <button className="--btn --btn-primary">
            <Link to={"/pricing"}>View Pricing</Link>
          </button>
        </div>
      </section>
    </>
  );
};

export default Cancel;
