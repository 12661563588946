import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  deleteLink,
  getUserLinks,
  selectLinks,
} from "../../../redux/features/link/linkSlice";
import "./LinkList.scss";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";
import { BiBarChartAlt } from "react-icons/bi";
import { Spinner } from "../../loader/Loader";
import Search from "../../search/Search";
import { ShortLinkProp } from "../../../models/models";
import { copyText, formatNumber, shortenText } from "../../../utils";
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactPaginate from "react-paginate";
import {
  FILTER_BY_TAG,
  FILTER_LINKS,
  selectFilteredLinks,
} from "../../../redux/features/filter/filterSlice";
import ClickToCopy from "../../clickToCopy/ClickToCopy";
import { format } from "date-fns";
import LinkStats from "../../userStats/LinkStats";

const LinkList = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>("");
  const [tag, setTag] = useState<string>("All");

  const { links, isLoading, isSuccess, message } = useSelector(
    (state: any) => state.link
  );
  const filteredLinks = useSelector(selectFilteredLinks);
  const allTags = [
    "All",
    // @ts-ignore
    ...new Set(links.map((link) => link.tag)),
  ];
  const tags = allTags.filter(function (value, index, arr) {
    return value !== undefined;
  });

  useEffect(() => {
    // @ts-ignore
    dispatch(getUserLinks());
  }, [dispatch]);

  const removeLink = async (alias: string) => {
    // @ts-ignore
    await dispatch(deleteLink(alias));
    // @ts-ignore
    dispatch(getUserLinks());
  };

  const confirmDelete = (alias: string | any) => {
    confirmAlert({
      title: "Delete This Link",
      message: "Are you sure to do delete this Link?",
      buttons: [
        {
          label: "Delete",
          onClick: () => removeLink(alias),
        },
        {
          label: "Cancel",
          // onClick: () => alert("Click No"),
        },
      ],
    });
  };

  // Begin Pagination
  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filteredLinks.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredLinks.length / itemsPerPage);

  // Invoke when user click
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % filteredLinks.length;
    setItemOffset(newOffset);
  };
  // End Pagination

  useEffect(() => {
    dispatch(FILTER_LINKS({ links, search }));
  }, [dispatch, links, search]);

  useEffect(() => {
    dispatch(FILTER_BY_TAG({ links, tag }));
  }, [dispatch, links, tag]);

  return (
    <>
      <div className="link-list">
        {/* <pre>{JSON.stringify(first, null, 2)}</pre> */}
        {isLoading && <Spinner />}
        <LinkStats />
        <hr />

        <div className="table">
          <div className="--flex-between">
            <div className="sort">
              <div className="--flex-start" style={{ width: "200px" }}>
                <select
                  value={tag}
                  onChange={(e: any) => setTag(e.target.value)}
                >
                  {tags.map((tag, index) => {
                    return (
                      <option key={index} value={tag}>
                        {tag}
                      </option>
                    );
                  })}
                </select>
                <span>
                  <h4
                    style={{ width: "100px" }}
                  >{`Links~ ${filteredLinks.length} `}</h4>
                </span>
              </div>
            </div>
            <span>
              <Search
                value={search}
                placeholder="Search Links"
                onChange={(e: any) => setSearch(e.target.value)}
              />
            </span>
          </div>
          {/* Table */}
          {!isLoading && links.length === 0 ? (
            <div>
              <p>No Links found...</p>
              <Link to="/create-link">
                <button className="--btn --btn-primary">
                  Create Shortlink
                </button>
              </Link>
            </div>
          ) : (
            <table style={{ zIndex: 1 }}>
              <thead>
                <tr>
                  <th>s/n</th>
                  <th>Title</th>
                  <th>URL</th>
                  <th>ShortLink</th>
                  <th>Clicks</th>
                  <th>Tag</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody style={{ zIndex: 1 }}>
                {currentItems.map((link: object, index: number) => {
                  const {
                    _id,
                    title,
                    longLink,
                    shortLink,
                    tag,
                    clicks,
                    domain,
                  }: ShortLinkProp = link;

                  return (
                    <tr key={_id}>
                      <td>{index + 1}</td>
                      <td>{shortenText(title, 15)}</td>
                      <td>
                        {shortenText(longLink?.replace("https://", ""), 21)}
                      </td>
                      <td className="copy-link">
                        <ClickToCopy text={`https://${domain}/${shortLink}`} />

                        <span>
                          {domain?.replace("https://www.", "")}/
                          {shortenText(shortLink, 10)}
                        </span>
                      </td>

                      <td>
                        <BiBarChartAlt size={18} color="#333" />
                        &nbsp;
                        {formatNumber.format(clicks)}
                      </td>

                      <td>{tag ? shortenText(tag, 15) : "-"}</td>

                      <td className="icons">
                        <span>
                          <Tooltip
                            placement="top"
                            trigger={["hover"]}
                            overlay={<span>View Link Stats</span>}
                          >
                            <Link to={`/link/${shortLink}`}>
                              <AiOutlineEye size={25} color="purple" />
                            </Link>
                          </Tooltip>
                        </span>
                        <span>
                          <Tooltip
                            placement="top"
                            trigger={["hover"]}
                            overlay={<span>Edit Link</span>}
                          >
                            <Link to={`/edit-link/${shortLink}`}>
                              <FaEdit size={20} color="green" />
                            </Link>
                          </Tooltip>
                        </span>
                        <span>
                          <Tooltip
                            placement="top"
                            trigger={["hover"]}
                            overlay={<span>Delete Link</span>}
                          >
                            <FaTrashAlt
                              size={20}
                              color="red"
                              onClick={() => confirmDelete(shortLink)}
                            />
                          </Tooltip>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <hr />
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="Prev"
          // @ts-ignore
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="activePage"
        />
      </div>
    </>
  );
};

export default LinkList;
