import React, { useEffect, useRef, useState } from "react";
import "./CustomDomain.scss";
import Card from "../../components/card/Card";
import { Spinner } from "../../components/loader/Loader";
import axios from "axios";
import { toast } from "react-toastify";
import { isValidDomain } from "../../utils/index";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  RESET_DOMAIN,
  createDomain,
} from "../../redux/features/customDomain/domainSlice";
import { useSelector } from "react-redux";
import PageMenu from "../../components/pageMenu/PageMenu";

const activeLink = ({ isActive }) => (isActive ? "activePage" : "nothing");

export const item1 = (
  <NavLink to="/custom-domains" className={activeLink}>
    Add Domain
  </NavLink>
);
export const item2 = (
  <NavLink to="/view-custom-domains" className={activeLink}>
    View Domains
  </NavLink>
);

const CustomDomain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, isSuccess, message } = useSelector(
    (state) => state.domain
  );
  const [domain, setDomain] = useState("");
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const createCustomDomain = async (e) => {
    e.preventDefault();
    if (!domain) {
      return toast.error("Please add a domain or subdomain");
    }
    const domainIsValid = isValidDomain(domain);
    if (!domainIsValid) {
      return toast.error("Please enter a valid domain or subdomain");
    }
    const formData = {
      domain,
    };
    console.log(formData);
    await dispatch(createDomain(formData));
  };

  useEffect(() => {
    if (isSuccess && message.includes("Custom Domain Added")) {
      navigate("/view-custom-domains");
    }
    dispatch(RESET_DOMAIN());
  }, [isSuccess, message, navigate, dispatch]);

  return (
    <div>
      <br />
      <h3>Add Custom Domain</h3>
      <PageMenu link1={item1} link2={item2} />
      <br />
      <div className="--flex-start change-password">
        <Card cardClass={"card"}>
          <>
            <form onSubmit={createCustomDomain}>
              <p className="req">
                <label>Domain or subdomain</label>
                <code>Eg: example.com or go.example.com</code>
                <input
                  ref={inputRef}
                  type="text"
                  placeholder="Add a domain"
                  required
                  name="title"
                  value={domain}
                  // @ts-ignore
                  onChange={(e) => setDomain(e.target.value)}
                />
              </p>

              {isLoading ? (
                <Spinner />
              ) : (
                <button
                  type="submit"
                  className="--btn --btn-danger --btn-block"
                >
                  Add Custom Domain
                </button>
              )}
            </form>
          </>
        </Card>
      </div>
    </div>
  );
};

export default CustomDomain;
