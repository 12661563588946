import React from "react";

const Contact = () => {
  return (
    <div>
      <h2>Contact</h2>
      <p>
        Send us a email and we will revert ASAP{" "}
        <button className="--btn --btn-primary">
          <a href="mailto:zinotrust@gmail.com">Send mail</a>
        </button>
      </p>
    </div>
  );
};

export default Contact;
