import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import domainService from "./domainService";

const initialState = {
  domain: null,
  domains: [],
  verifiedDomains: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create Ad
export const createDomain = createAsyncThunk(
  "domain/create",
  async (formData, thunkAPI) => {
    try {
      return await domainService.createDomain(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Domains
export const getDomains = createAsyncThunk(
  "domain/getDomains",
  async (_, thunkAPI) => {
    try {
      return await domainService.getDomains();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Delete Domain
export const deleteDomain = createAsyncThunk(
  "domain/deleteDomain",
  async (alias, thunkAPI) => {
    try {
      // console.log(alias);
      return await domainService.deleteDomain(alias);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const domainSlice = createSlice({
  name: "domain",
  initialState,
  reducers: {
    RESET_DOMAIN(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
    GET_DOMAINS(state, action) {
      // console.log(action.payload);
      state.verifiedDomains = action.payload;
      // console.log(state.verifiedDomains);
    },
  },
  extraReducers: (builder) => {
    builder
      // Create domain
      .addCase(createDomain.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createDomain.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.isError = false;
        toast.success(action.payload);
        // console.log(action.payload);
      })
      .addCase(createDomain.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
        // console.log(action.payload);
      })
      // Get Domains
      .addCase(getDomains.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDomains.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        console.log(action.payload);
        if (
          action.payload &&
          action.payload.message &&
          action.payload.message.includes(
            "Something went wrong" || "No custom domain added"
          )
        ) {
          return toast.error(action.payload);
        }
        if (action.payload && Array.isArray(action.payload)) {
          state.domains = action.payload;
        }
      })
      .addCase(getDomains.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
        // console.log(action.payload);
      })
      // Delete Domain
      .addCase(deleteDomain.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDomain.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload;
        toast.success(action.payload);
        // console.log(action.payload);
      })
      .addCase(deleteDomain.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
        // console.log(action.payload);
      });
  },
});

export const { GET_DOMAINS, RESET_DOMAIN } = domainSlice.actions;

export const selectVerifiedDomains = (state) => state.domain.verifiedDomains;

export default domainSlice.reducer;
