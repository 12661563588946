import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LinkForm from "../../components/link/linkForm/LinkForm";
import {
  RESET_LINK,
  getLink,
  updateShortLink,
} from "../../redux/features/link/linkSlice";
import { validateUrl } from "../../utils";
import "../createLink/createLink/CreateLink.scss";
import { selectVerifiedDomains } from "../../redux/features/customDomain/domainSlice";

interface LinkProps {
  title: string;
  domain: string;
  longURL: string;
  shortURL: string;
  tag: string;
}

const EditLink = () => {
  const { alias } = useParams();
  const [linkData, setLinkData] = useState<LinkProps>({
    title: "",
    domain: "zli.to",
    longURL: "",
    shortURL: "",
    tag: "",
  });
  const { title, longURL, domain, shortURL, tag } = linkData;

  const { links, link, isLoading, isSuccess, message } = useSelector(
    (state: any) => state.link
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const domains = useSelector(selectVerifiedDomains);
  // @ts-ignore
  let allDomains = [...new Set(domains)];

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setLinkData({ ...linkData, [name]: value });
  };

  // Get Link info and populate form redux
  useEffect(() => {
    if (links) {
      const rLink = links.find((link: any) => link.shortLink === alias);

      setLinkData({
        ...linkData,
        title: rLink?.title || "",
        domain: rLink?.domain || "zli.to",
        longURL: rLink?.longLink || "",
        shortURL: rLink?.shortLink || "",
        tag: rLink?.tag || "",
      });
    }
  }, [links, alias]);

  // Fetch Link
  useEffect(() => {
    if (links.length === 0) {
      // @ts-ignore
      dispatch(getLink(alias));
    }
  }, [dispatch, links, alias]);

  useEffect(() => {
    if (links.length === 0 && link) {
      setLinkData({
        ...linkData,
        title: link?.title || "",
        domain: link?.domain || "",
        longURL: link?.longLink || "",
        shortURL: link?.shortLink || "",
        tag: link?.tag || "",
      });
    }
  }, [links, link]);

  const editLink = async (e: any) => {
    e.preventDefault();
    if (!linkData.title || !linkData.longURL) {
      return toast.error("Please add title and Long URL");
    }
    const urlIsValid = validateUrl(longURL);

    if (!longURL.startsWith("https://")) {
      return toast.error("Please start Long Url with https://");
    }
    if (!urlIsValid) {
      return toast.error("Please enter a valid url");
    }
    const formData = {
      title,
      newDomain: domain,
      newLongUrl: longURL,
      newTag: tag,
    };

    // @ts-ignore
    await dispatch(updateShortLink({ alias, formData }));
  };

  useEffect(() => {
    if (isSuccess && message.includes("updated")) {
      // navigate("/");
      console.log("");
    }
    dispatch(RESET_LINK());
  }, [isSuccess, message, navigate, dispatch]);

  return (
    <div>
      {/* <Loader /> */}
      <br />
      <h3>Edit ShortLink</h3>
      <div className="--flex-start change-password">
        <LinkForm
          onSubmit={editLink}
          linkData={linkData}
          allDomains={allDomains}
          handleInputChange={handleInputChange}
          isLoading={isLoading}
          btnText={"Update ShortLink"}
        />
      </div>
    </div>
  );
};

export default EditLink;
