import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../redux/features/auth/authSlice";
import emailReducer from "../redux/features/email/emailSlice";
import filterReducer from "../redux/features/filter/filterSlice";
import linkReducer from "../redux/features/link/linkSlice";
import adsReducer from "../redux/features/ads/adSlice";
import pageReducer from "../redux/features/adsPage/pageSlice";
import domainReducer from "../redux/features/customDomain/domainSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    email: emailReducer,
    filter: filterReducer,
    link: linkReducer,
    ads: adsReducer,
    page: pageReducer,
    domain: domainReducer,
  },
});
