import React from "react";
import Card from "../../components/card/Card";

const AdsBeforeLink = () => {
  return (
    <section>
      <div className="container">
        <h3>Ads Before Link ~ A.B.L</h3>
        <div className="--flex-start">
          <button className="--btn --btn-danger">Create Ads Card</button>
          <button className="--btn --btn-primary">Create A.B.L</button>
        </div>
        <br />
        <Card cardClass={"card"}>
          <h2>Test</h2>
        </Card>
      </div>
    </section>
  );
};

export default AdsBeforeLink;
