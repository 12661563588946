import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const API_URL = `${BACKEND_URL}/api/page/`;

// Create New Page
const createAdPage = async (newFormData) => {
  const response = await axios.post(API_URL + "create", newFormData);
  return response.data;
};

// Get all Ads
const getAdPages = async () => {
  const response = await axios.get(API_URL + "getPages");
  return response.data;
};

// Delete a Product
const deleteAdPage = async (id) => {
  const response = await axios.delete(API_URL + id);
  return response.data.message;
};

// Get Ad
const getAdPage = async (id) => {
  const response = await axios.get(API_URL + id);
  return response.data;
};

// Update Ad
const updateAdPage = async (editID, newFormData) => {
  const response = await axios.patch(`${API_URL}${editID}`, newFormData);
  return response.data;
};

const pageService = {
  createAdPage,
  getAdPages,
  deleteAdPage,
  getAdPage,
  updateAdPage,
};

export default pageService;
