import React, { useEffect, useLayoutEffect, useState } from "react";
import Card from "../../components/card/Card";
import profileImg from "../../assets/avatarr.png";
import "./Profile.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getUser,
  selectUser,
  updateUser,
} from "../../redux/features/auth/authSlice";
import Loader from "../../components/loader/Loader";
import { shortenText } from "../../utils";
import useRedirectLoggedOutUser from "../../customHook/useRedirectLoggedOutUser";

const Profile = () => {
  useRedirectLoggedOutUser("/login");

  const dispatch = useDispatch();
  const { isLoading, isLoggedIn, isSuccess, message, user } = useSelector(
    (state: any) => state.auth
  );
  const initialState = {
    name: user?.name || "",
    email: user?.email || "",
    phone: user?.phone || "",
    role: user?.role || "",
  };
  const [profile, setProfile] = useState(initialState);

  useEffect(() => {
    // @ts-ignore
    dispatch(getUser());
  }, [dispatch]);
  // console.log(user);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const saveProfile = async (e: any) => {
    e.preventDefault();

    // Save profile to MongoDB
    const userData = {
      name: profile.name,
      phone: profile.phone,
    };
    // @ts-ignore
    dispatch(updateUser(userData));
  };

  useLayoutEffect(() => {
    if (user) {
      setProfile({
        ...profile,
        name: user.name,
        email: user.email,
        phone: user.phone,
        role: user.role,
      });
    }
  }, [user]);

  return (
    <>
      <section>
        {isLoading && <Loader />}

        <div className="container">
          <h3>Profile</h3>
          <div className="--flex-start profile">
            <Card cardClass={"card"}>
              {!isLoading && user && (
                <>
                  <form onSubmit={saveProfile}>
                    <p>
                      <label>Name:</label>
                      <input
                        type="text"
                        name="name"
                        value={profile?.name}
                        onChange={handleInputChange}
                      />
                    </p>
                    <p>
                      <label>Email:</label>
                      <input
                        type="email"
                        name="email"
                        value={profile?.email}
                        onChange={handleInputChange}
                        disabled
                      />
                    </p>
                    <p>
                      <label>Phone:</label>
                      <input
                        type="text"
                        name="phone"
                        value={profile?.phone}
                        onChange={handleInputChange}
                      />
                    </p>

                    <button className="--btn --btn-primary --btn-block">
                      Update Profile
                    </button>
                  </form>
                </>
              )}
            </Card>
          </div>
        </div>
      </section>
    </>
  );
};

export const UserName = () => {
  const user = useSelector(selectUser);

  const username = user?.name || "";

  return (
    <h3>
      <span className="--fw-thin">Hello, </span>
      <span className="--color-danger">{shortenText(username, 9)}</span>
    </h3>
  );
};

export default Profile;
