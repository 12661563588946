import { useEffect, useState } from "react";
import Sub from "./Sub";
import "./SubList.css";
import axios from "axios";
import { BACKEND_URL } from "../../redux/features/link/linkService";
import {
  basic_one,
  basic_three,
  basic_two,
  business,
  enterprise,
  free,
  pro,
} from "../../utils";
import PriceButton from "./PriceButton";

export const getTheme = (plan: string) => {
  if (plan === free) {
    return "theme1";
  }
  if (plan === basic_one || plan === basic_two || plan === basic_three) {
    return "theme0";
  }
  if (plan === pro) {
    return "theme1";
  }
  if (plan === business) {
    return "theme2";
  }
  if (plan === enterprise) {
    return "theme3";
  }
};

const SubList = () => {
  const [toggleBtn, setToggleBtn] = useState<any>(false);
  const [basicPlan, setBasicPlan] = useState<any>(null);
  const [proPlan, setProPlan] = useState<any>(null);
  const [basicPrice, setBasicPrice] = useState<any>(0);
  const [proPrice, setProPrice] = useState<any>(0);
  const [prices, setPrices] = useState<any>([]);

  const [plan, setPlan] = useState<any>("Basic One");
  const [selectedPlan, setSelectedPlan] = useState<any>([]);

  const handleClick = () => {
    setToggleBtn(!toggleBtn);
  };
  //   Calc Percentage
  const calcPercent = (num: any, per: any) => {
    return ((num * 12) / 100) * per;
  };
  const revertPercent = (num: any, per: any) => {
    return ((num / per) * 100) / 12;
  };

  // Get Sub Price
  const fetchPrices = async () => {
    const res = await axios.get(`${BACKEND_URL}/api/subs/getPrices`);
    // console.log(res.data);
    setPrices(res.data);
    return res.data;
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  const createSession = async (priceId: string) => {
    console.log(priceId);

    const { data } = await axios.post(`${BACKEND_URL}/api/subs/createSession`, {
      priceId,
    });
    // console.log(data);

    window.open(data.url);
  };

  const handleCheck = (e: any) => {
    setPlan(e.target.value);
  };

  useEffect(() => {
    if (prices.length > 0) {
      const thisPlan = prices?.filter((plann: any) => {
        return plann.nickname === plan;
      });
      setSelectedPlan(thisPlan);
    }
  }, [prices, plan]);

  return (
    <section className="main">
      <div className="container --center-all">
        <div className="title ">
          <h2>Pricing</h2>
          <div className="--line"></div>
          {/* <div className="--flex-center --my2 ">
            <p>Monthly</p>
            <div className="--mx2">
              <span
                className={toggleBtn ? "toggle-btn toggled" : "toggle-btn"}
                onClick={handleClick}
              >
                <div className={toggleBtn ? "ball move" : "ball"}></div>
              </span>
            </div>
            <p>Yearly</p>
          </div> */}
        </div>
      </div>

      <div className="subs --flex-dir-column">
        <div className="priceButtons">
          {prices &&
            prices.map((price: any) => {
              return (
                <PriceButton
                  key={price.nickname}
                  handleCheck={handleCheck}
                  planName={price.nickname}
                  price={price.unit_amount / 100}
                />
              );
            })}
        </div>

        <div className="priceBox">
          {prices &&
            selectedPlan.map((price: any) => {
              return (
                <div key={price.nickname}>
                  <Sub
                    plan={price.nickname}
                    theme={getTheme(price.nickname)}
                    free={price.nickname === free && true}
                    basic_one={price.nickname === basic_one && true}
                    basic_two={price.nickname === basic_two && true}
                    basic_three={price.nickname === basic_three && true}
                    pro={price.nickname === pro && true}
                    business={price.nickname === business && true}
                    enterprise={price.nickname === enterprise && true}
                    price={price.unit_amount / 100}
                    yearly={toggleBtn}
                    onBuy={() => createSession(price.id)}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default SubList;
