import React from "react";
import { NavLink } from "react-router-dom";
import "./PageMenu.scss";

interface linkProps {
  link1: any;
  link2: any;
}

const PageMenu = ({ link1, link2 }: linkProps) => {
  return (
    <div>
      <nav className="pageMenu --p --mb">
        <ul className="home-links">
          <li>{link1}</li>
          <li>{link2}</li>
        </ul>
      </nav>
    </div>
  );
};

export default PageMenu;
