import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import linkService from "./linkService";
import { format } from "date-fns";

const initialState = {
  link: null,
  links: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  clicksPerDay: 0,
  clicksPerMonth: 0,
  clicksPerYear: 0,
  clicksToday: 0,
  clicksThisMonth: 0,
};

// Create ShortLink
export const createShortLink = createAsyncThunk(
  "link/create",
  async (formData, thunkAPI) => {
    try {
      return await linkService.createShortLink(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// getUserLinks
export const getUserLinks = createAsyncThunk(
  "link/getUserLinks",
  async (_, thunkAPI) => {
    try {
      return await linkService.getUserLinks();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// deleteLink
export const deleteLink = createAsyncThunk(
  "link/deleteLink",
  async (alias, thunkAPI) => {
    try {
      return await linkService.deleteLink(alias);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Link
export const updateShortLink = createAsyncThunk(
  "link/updateShortLink",
  async ({ formData, alias }, thunkAPI) => {
    try {
      return await linkService.updateShortLink(alias, formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Link
export const getLink = createAsyncThunk(
  "link/getLink",
  async (alias, thunkAPI) => {
    try {
      return await linkService.getLink(alias);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get current day with date fns
const date = new Date();
const today = format(date, "E");
const monthdate = format(date, "PP");
const currentDay = today + ", " + monthdate;

const currentMonth = date.toLocaleString("default", {
  month: "short",
});
const currentYear = date.getFullYear();

const linkSlice = createSlice({
  name: "link",
  initialState,
  reducers: {
    RESET_LINK(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
    CALC_CLICKS_PER_DAY(state) {
      // Gotten current day
      const cpdArr = state.links.map(({ clicksPerDay }) => ({ clicksPerDay }));
      const newArray = [];
      for (let i = 0; i < cpdArr.length; i++) {
        const clicksPerDayArray = cpdArr[i].clicksPerDay;
        for (let j = 0; j < clicksPerDayArray.length; j++) {
          newArray.push(clicksPerDayArray[j]);
        }
      }

      // Calculate clicks this day
      let clicksToday = 0;

      for (let i = 0; i < newArray.length; i++) {
        const clickObject = newArray[i];
        if (clickObject.day === currentDay) {
          clicksToday += clickObject.count;
        }
      }
      // console.log(clicksToday);
      state.clicksPerDay = clicksToday;
    },
    CALC_CLICKS_PER_MONTH(state) {
      const cpmArr = state.links.map(({ clicksPerMonth }) => ({
        clicksPerMonth,
      }));
      // get the current month and year using the Date object

      // loop through each object in the data array
      let totalClicks = 0;
      cpmArr.forEach((obj) => {
        obj.clicksPerMonth.forEach((clicksObj) => {
          if (clicksObj.month === `${currentMonth}, ${currentYear}`) {
            totalClicks += clicksObj.count;
          }
        });
      });

      state.clicksPerMonth = totalClicks;
    },
    CALC_CLICKS_PER_YEAR(state) {
      const cpyArr = state.links.map(({ clicksPerYear }) => ({
        clicksPerYear,
      }));
      const currentYear = new Date().getFullYear();
      // loop through each object in the data array
      let totalClicks = 0;
      cpyArr.forEach((obj) => {
        obj.clicksPerYear.forEach((clicksObj) => {
          if (clicksObj.year === currentYear) {
            totalClicks += clicksObj.count;
          }
        });
      });
      // console.log(totalClicks);
      state.clicksPerYear = totalClicks;
    },
    CALC_CLICKS_TODAY(state, action) {
      const { document } = action.payload;
      // console.log(document);
      // console.log(document?.clicksPerDay);

      // const today = new Date().toDateString();
      let clicksToday = 0;

      // loop through the clicksPerDay
      for (let i = 0; i < document?.clicksPerDay.length; i++) {
        if (document?.clicksPerDay[i].day === currentDay) {
          clicksToday += document?.clicksPerDay[i].count;
        }
      }
      state.clicksToday = clicksToday;
    },
    CALC_CLICKS_THIS_MONTH(state, action) {
      const { document } = action.payload;
      let clicksThisMonth = 0;

      // loop through the clicksPerMonth array
      for (let i = 0; i < document.clicksPerMonth.length; i++) {
        if (
          document.clicksPerMonth[i].month === `${currentMonth}, ${currentYear}`
        ) {
          clicksThisMonth += document.clicksPerMonth[i].count;
        }
      }
      state.clicksThisMonth = clicksThisMonth;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Link
      .addCase(createShortLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createShortLink.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.link = action.payload;
        state.message = action.payload.message;
        toast.success(action.payload.message);
      })
      .addCase(createShortLink.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.link = null;
        toast.error(action.payload);
      })
      // getUserLinks
      .addCase(getUserLinks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserLinks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.links = action.payload;
        // console.log(action.payload);
      })
      .addCase(getUserLinks.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // deleteLink
      .addCase(deleteLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteLink.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success(action.payload);
      })
      .addCase(deleteLink.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // updateShortLink
      .addCase(updateShortLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateShortLink.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success(action.payload);
      })
      .addCase(updateShortLink.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // getLink
      .addCase(getLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLink.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.link = action.payload;
      })
      .addCase(getLink.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export const {
  RESET_LINK,
  CALC_CLICKS_PER_DAY,
  CALC_CLICKS_PER_MONTH,
  CALC_CLICKS_PER_YEAR,
  CALC_CLICKS_TODAY,
  CALC_CLICKS_THIS_MONTH,
} = linkSlice.actions;

export const selectLinks = (state) => state.link.links;

export const selectClicksPerDay = (state) => state.link.clicksPerDay;
export const selectClicksPerMonth = (state) => state.link.clicksPerMonth;
export const selectClicksToday = (state) => state.link.clicksToday;
export const selectClicksThisMonth = (state) => state.link.clicksThisMonth;

export default linkSlice.reducer;
